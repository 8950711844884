export default {
state: {
  sdateLocation:"",
  edateLocation:"",
  arrDateLocation:"",
  cwInx:null,
changwats:{

        "10": {
          "name": {
            "th": "กรุงเทพมหานคร",
            "en": "Bangkok"
          }
        },
        "11": {
          "name": {
            "th": "สมุทรปราการ",
            "en": "Samut Prakarn"
          }
        },
        "12": {
          "name": {
            "th": "นนทบุรี",
            "en": "Nonthaburi"
          }
        },
        "13": {
          "name": {
            "th": "ปทุมธานี",
            "en": "Pathum Thani"
          }
        },
        "14": {
          "name": {
            "th": "พระนครศรีอยุธยา",
            "en": "Phra Nakhon Si Ayutthaya"
          }
        },
        "15": {
          "name": {
            "th": "อ่างทอง",
            "en": "Ang Thong"
          }
        },
        "16": {
          "name": {
            "th": "ลพบุรี",
            "en": "Lop Buri"
          }
        },
        "17": {
          "name": {
            "th": "สิงห์บุรี",
            "en": "Sing Buri"
          }
        },
        "18": {
          "name": {
            "th": "ชัยนาท",
            "en": "Chai Nat"
          }
        },
        "19": {
          "name": {
            "th": "สระบุรี",
            "en": "Saraburi"
          }
        },
        "20": {
          "name": {
            "th": "ชลบุรี",
            "en": "Chon Buri"
          }
        },
        "21": {
          "name": {
            "th": "ระยอง",
            "en": "Rayong"
          }
        },
        "22": {
          "name": {
            "th": "จันทบุรี",
            "en": "Chanthaburi"
          }
        },
        "23": {
          "name": {
            "th": "ตราด",
            "en": "Trat"
          }
        },
        "24": {
          "name": {
            "th": "ฉะเชิงเทรา",
            "en": "Chachoengsao"
          }
        },
        "25": {
          "name": {
            "th": "ปราจีนบุรี",
            "en": "Prachin Buri"
          }
        },
        "26": {
          "name": {
            "th": "นครนายก",
            "en": "Nakhon Nayok"
          }
        },
        "27": {
          "name": {
            "th": "สระแก้ว",
            "en": "Sa kaeo"
          }
        },
        "30": {
          "name": {
            "th": "นครราชสีมา",
            "en": "Nakhon Ratchasima"
          }
        },
        "31": {
          "name": {
            "th": "บุรีรัมย์",
            "en": "Buri Ram"
          }
        },
        "32": {
          "name": {
            "th": "สุรินทร์",
            "en": "Surin"
          }
        },
        "33": {
          "name": {
            "th": "ศรีสะเกษ",
            "en": "Si Sa Ket"
          }
        },
        "34": {
          "name": {
            "th": "อุบลราชธานี",
            "en": "Ubon Ratchathani"
          }
        },
        "35": {
          "name": {
            "th": "ยโสธร",
            "en": "Yasothon"
          }
        },
        "36": {
          "name": {
            "th": "ชัยภูมิ",
            "en": "Chaiyaphum"
          }
        },
        "37": {
          "name": {
            "th": "อำนาจเจริญ",
            "en": "Amnat Charoen"
          }
        },
        "38": {
          "name": {
            "th": "บึงกาฬ",
            "en": "Bueng Kan"
          }
        },
        "39": {
          "name": {
            "th": "หนองบัวลำภู",
            "en": "Nong Bua Lam Phu"
          }
        },
        "40": {
          "name": {
            "th": "ขอนแก่น",
            "en": "Khon Kaen"
          }
        },
        "41": {
          "name": {
            "th": "อุดรธานี",
            "en": "Udon Thani"
          }
        },
        "42": {
          "name": {
            "th": "เลย",
            "en": "Loei"
          }
        },
        "43": {
          "name": {
            "th": "หนองคาย",
            "en": "Nong Khai"
          }
        },
        "44": {
          "name": {
            "th": "มหาสารคาม",
            "en": "Maha Sarakham"
          }
        },
        "45": {
          "name": {
            "th": "ร้อยเอ็ด",
            "en": "Roi Et"
          }
        },
        "46": {
          "name": {
            "th": "กาฬสินธุ์",
            "en": "Kalasin"
          }
        },
        "47": {
          "name": {
            "th": "สกลนคร",
            "en": "Sakon Nakhon"
          }
        },
        "48": {
          "name": {
            "th": "นครพนม",
            "en": "Nakhon Phanom"
          }
        },
        "49": {
          "name": {
            "th": "มุกดาหาร",
            "en": "Mukdahan"
          }
        },
        "50": {
          "name": {
            "th": "เชียงใหม่",
            "en": "Chiang Mai"
          }
        },
        "51": {
          "name": {
            "th": "ลำพูน",
            "en": "Lamphun"
          }
        },
        "52": {
          "name": {
            "th": "ลำปาง",
            "en": "Lampang"
          }
        },
        "53": {
          "name": {
            "th": "อุตรดิตถ์",
            "en": "Uttaradit"
          }
        },
        "54": {
          "name": {
            "th": "แพร่",
            "en": "Phrae"
          }
        },
        "55": {
          "name": {
            "th": "น่าน",
            "en": "Nan"
          }
        },
        "56": {
          "name": {
            "th": "พะเยา",
            "en": "Phayao"
          }
        },
        "57": {
          "name": {
            "th": "เชียงราย",
            "en": "Chiang Rai"
          }
        },
        "58": {
          "name": {
            "th": "แม่ฮ่องสอน",
            "en": "Mae Hong Son"
          }
        },
        "60": {
          "name": {
            "th": "นครสวรรค์",
            "en": "Nakhon Sawan"
          }
        },
        "61": {
          "name": {
            "th": "อุทัยธานี",
            "en": "Uthai Thani"
          }
        },
        "62": {
          "name": {
            "th": "กำแพงเพชร",
            "en": "Kamphaeng Phet"
          }
        },
        "63": {
          "name": {
            "th": "ตาก",
            "en": "Tak"
          }
        },
        "64": {
          "name": {
            "th": "สุโขทัย",
            "en": "Sukhothai"
          }
        },
        "65": {
          "name": {
            "th": "พิษณุโลก",
            "en": "Phitsanulok"
          }
        },
        "66": {
          "name": {
            "th": "พิจิตร",
            "en": "Phichit"
          }
        },
        "67": {
          "name": {
            "th": "เพชรบูรณ์",
            "en": "Phetchabun"
          }
        },
        "70": {
          "name": {
            "th": "ราชบุรี",
            "en": "Ratchaburi"
          }
        },
        "71": {
          "name": {
            "th": "กาญจนบุรี",
            "en": "Kanchanaburi"
          }
        },
        "72": {
          "name": {
            "th": "สุพรรณบุรี",
            "en": "Suphan Buri"
          }
        },
        "73": {
          "name": {
            "th": "นครปฐม",
            "en": "Nakhon Pathom"
          }
        },
        "74": {
          "name": {
            "th": "สมุทรสาคร",
            "en": "Samut Sakhon"
          }
        },
        "75": {
          "name": {
            "th": "สมุทรสงคราม",
            "en": "Samut Songkhram"
          }
        },
        "76": {
          "name": {
            "th": "เพชรบุรี",
            "en": "Phetchaburi"
          }
        },
        "77": {
          "name": {
            "th": "ประจวบคีรีขันธ์",
            "en": "Prachuap Khiri Khan"
          }
        },
        "80": {
          "name": {
            "th": "นครศรีธรรมราช",
            "en": "Nakhon Si Thammarat"
          }
        },
        "81": {
          "name": {
            "th": "กระบี่",
            "en": "Krabi"
          }
        },
        "82": {
          "name": {
            "th": "พังงา",
            "en": "Phang-nga"
          }
        },
        "83": {
          "name": {
            "th": "ภูเก็ต",
            "en": "Phuket"
          }
        },
        "84": {
          "name": {
            "th": "สุราษฎร์ธานี",
            "en": "Surat Thani"
          }
        },
        "85": {
          "name": {
            "th": "ระนอง",
            "en": "Ranong"
          }
        },
        "86": {
          "name": {
            "th": "ชุมพร",
            "en": "Chumphon"
          }
        },
        "90": {
          "name": {
            "th": "สงขลา",
            "en": "Songkhla"
          }
        },
        "91": {
          "name": {
            "th": "สตูล",
            "en": "Satun"
          }
        },
        "92": {
          "name": {
            "th": "ตรัง",
            "en": "Trang"
          }
        },
        "93": {
          "name": {
            "th": "พัทลุง",
            "en": "Phatthalung"
          }
        },
        "94": {
          "name": {
            "th": "ปัตตานี",
            "en": "Pattani"
          }
        },
        "95": {
          "name": {
            "th": "ยะลา",
            "en": "Yala"
          }
        },
        "96": {
          "name": {
            "th": "นราธิวาส",
            "en": "Narathiwat"
          }
        }
},
amphoes:{
    "1001": {
      "name": {
        "th": "พระนคร",
        "en": "Phra Nakhon"
      },
      "changwat_id": "10"
    },
    "1002": {
      "name": {
        "th": "ดุสิต",
        "en": "Dusit"
      },
      "changwat_id": "10"
    },
    "1003": {
      "name": {
        "th": "หนองจอก",
        "en": "Nong Chok"
      },
      "changwat_id": "10"
    },
    "1004": {
      "name": {
        "th": "บางรัก",
        "en": "Bang Rak"
      },
      "changwat_id": "10"
    },
    "1005": {
      "name": {
        "th": "บางเขน",
        "en": "Bang Khen"
      },
      "changwat_id": "10"
    },
    "1006": {
      "name": {
        "th": "บางกะปิ",
        "en": "Bang Kapi"
      },
      "changwat_id": "10"
    },
    "1007": {
      "name": {
        "th": "ปทุมวัน",
        "en": "Pathum Wan"
      },
      "changwat_id": "10"
    },
    "1008": {
      "name": {
        "th": "ป้อมปราบศัตรูพ่าย",
        "en": "Pom Prap Sattru Phai"
      },
      "changwat_id": "10"
    },
    "1009": {
      "name": {
        "th": "พระโขนง",
        "en": "Phra Khanong"
      },
      "changwat_id": "10"
    },
    "1010": {
      "name": {
        "th": "มีนบุรี",
        "en": "Min Buri"
      },
      "changwat_id": "10"
    },
    "1011": {
      "name": {
        "th": "ลาดกระบัง",
        "en": "Lat Krabang"
      },
      "changwat_id": "10"
    },
    "1012": {
      "name": {
        "th": "ยานนาวา",
        "en": "Yan Nawa"
      },
      "changwat_id": "10"
    },
    "1013": {
      "name": {
        "th": "สัมพันธวงศ์",
        "en": "Samphanthawong"
      },
      "changwat_id": "10"
    },
    "1014": {
      "name": {
        "th": "พญาไท",
        "en": "Phaya Thai"
      },
      "changwat_id": "10"
    },
    "1015": {
      "name": {
        "th": "ธนบุรี",
        "en": "Thon Buri"
      },
      "changwat_id": "10"
    },
    "1016": {
      "name": {
        "th": "บางกอกใหญ่",
        "en": "Bangkok Yai"
      },
      "changwat_id": "10"
    },
    "1017": {
      "name": {
        "th": "ห้วยขวาง",
        "en": "Huai Khwang"
      },
      "changwat_id": "10"
    },
    "1018": {
      "name": {
        "th": "คลองสาน",
        "en": "Khlong San"
      },
      "changwat_id": "10"
    },
    "1019": {
      "name": {
        "th": "ตลิ่งชัน",
        "en": "Taling Chan"
      },
      "changwat_id": "10"
    },
    "1020": {
      "name": {
        "th": "บางกอกน้อย",
        "en": "Bangkok Noi"
      },
      "changwat_id": "10"
    },
    "1021": {
      "name": {
        "th": "บางขุนเทียน",
        "en": "Bang Khun Thian"
      },
      "changwat_id": "10"
    },
    "1022": {
      "name": {
        "th": "ภาษีเจริญ",
        "en": "Phasi Charoen"
      },
      "changwat_id": "10"
    },
    "1023": {
      "name": {
        "th": "หนองแขม",
        "en": "Nong Khaem"
      },
      "changwat_id": "10"
    },
    "1024": {
      "name": {
        "th": "ราษฎร์บูรณะ",
        "en": "Rat Burana"
      },
      "changwat_id": "10"
    },
    "1025": {
      "name": {
        "th": "บางพลัด",
        "en": "Bang Phlat"
      },
      "changwat_id": "10"
    },
    "1026": {
      "name": {
        "th": "ดินแดง",
        "en": "Din Daeng"
      },
      "changwat_id": "10"
    },
    "1027": {
      "name": {
        "th": "บึงกุ่ม",
        "en": "Bueng Kum"
      },
      "changwat_id": "10"
    },
    "1028": {
      "name": {
        "th": "สาทร",
        "en": "Sathon"
      },
      "changwat_id": "10"
    },
    "1029": {
      "name": {
        "th": "บางซื่อ",
        "en": "Bang Su"
      },
      "changwat_id": "10"
    },
    "1030": {
      "name": {
        "th": "จตุจักร",
        "en": "Chatuchak"
      },
      "changwat_id": "10"
    },
    "1031": {
      "name": {
        "th": "บางคอแหลม",
        "en": "Bang Kho Laem"
      },
      "changwat_id": "10"
    },
    "1032": {
      "name": {
        "th": "ประเวศ",
        "en": "Prawet"
      },
      "changwat_id": "10"
    },
    "1033": {
      "name": {
        "th": "คลองเตย",
        "en": "Khlong Toei"
      },
      "changwat_id": "10"
    },
    "1034": {
      "name": {
        "th": "สวนหลวง",
        "en": "Suan Luang"
      },
      "changwat_id": "10"
    },
    "1035": {
      "name": {
        "th": "จอมทอง",
        "en": "Chom Thong"
      },
      "changwat_id": "10"
    },
    "1036": {
      "name": {
        "th": "ดอนเมือง",
        "en": "Don Mueang"
      },
      "changwat_id": "10"
    },
    "1037": {
      "name": {
        "th": "ราชเทวี",
        "en": "Ratchathewi"
      },
      "changwat_id": "10"
    },
    "1038": {
      "name": {
        "th": "ลาดพร้าว",
        "en": "Lat Phrao"
      },
      "changwat_id": "10"
    },
    "1039": {
      "name": {
        "th": "วัฒนา",
        "en": "Watthana"
      },
      "changwat_id": "10"
    },
    "1040": {
      "name": {
        "th": "บางแค",
        "en": "Bang Khae"
      },
      "changwat_id": "10"
    },
    "1041": {
      "name": {
        "th": "หลักสี่",
        "en": "Lak Si"
      },
      "changwat_id": "10"
    },
    "1042": {
      "name": {
        "th": "สายไหม",
        "en": "Sai Mai"
      },
      "changwat_id": "10"
    },
    "1043": {
      "name": {
        "th": "คันนายาว",
        "en": "Khan Na Yao"
      },
      "changwat_id": "10"
    },
    "1044": {
      "name": {
        "th": "สะพานสูง",
        "en": "Saphan Sung"
      },
      "changwat_id": "10"
    },
    "1045": {
      "name": {
        "th": "วังทองหลาง",
        "en": "Wang Thonglang"
      },
      "changwat_id": "10"
    },
    "1046": {
      "name": {
        "th": "คลองสามวา",
        "en": "Khlong Sam Wa"
      },
      "changwat_id": "10"
    },
    "1047": {
      "name": {
        "th": "บางนา",
        "en": "Bang Na"
      },
      "changwat_id": "10"
    },
    "1048": {
      "name": {
        "th": "ทวีวัฒนา",
        "en": "Thawi Watthana"
      },
      "changwat_id": "10"
    },
    "1049": {
      "name": {
        "th": "ทุ่งครุ",
        "en": "Thung Khru"
      },
      "changwat_id": "10"
    },
    "1050": {
      "name": {
        "th": "บางบอน",
        "en": "Bang Bon"
      },
      "changwat_id": "10"
    },
    "1101": {
      "name": {
        "th": "เมืองสมุทรปราการ",
        "en": "Mueang Samut Prakan"
      },
      "changwat_id": "11"
    },
    "1102": {
      "name": {
        "th": "บางบ่อ",
        "en": "Bang Bo"
      },
      "changwat_id": "11"
    },
    "1103": {
      "name": {
        "th": "บางพลี",
        "en": "Bang Phli"
      },
      "changwat_id": "11"
    },
    "1104": {
      "name": {
        "th": "พระประแดง",
        "en": "Phra Pradaeng"
      },
      "changwat_id": "11"
    },
    "1105": {
      "name": {
        "th": "พระสมุทรเจดีย์",
        "en": "Phra Samut Chedi"
      },
      "changwat_id": "11"
    },
    "1106": {
      "name": {
        "th": "บางเสาธง",
        "en": "Bang Sao Thong"
      },
      "changwat_id": "11"
    },
    "1201": {
      "name": {
        "th": "เมืองนนทบุรี",
        "en": "Mueang Nonthaburi"
      },
      "changwat_id": "12"
    },
    "1202": {
      "name": {
        "th": "บางกรวย",
        "en": "Bang Kruai"
      },
      "changwat_id": "12"
    },
    "1203": {
      "name": {
        "th": "บางใหญ่",
        "en": "Bang Yai"
      },
      "changwat_id": "12"
    },
    "1204": {
      "name": {
        "th": "บางบัวทอง",
        "en": "Bang Bua Thong"
      },
      "changwat_id": "12"
    },
    "1205": {
      "name": {
        "th": "ไทรน้อย",
        "en": "Sai Noi"
      },
      "changwat_id": "12"
    },
    "1206": {
      "name": {
        "th": "ปากเกร็ด",
        "en": "Pak Kret"
      },
      "changwat_id": "12"
    },
    "1301": {
      "name": {
        "th": "เมืองปทุมธานี",
        "en": "Mueang Pathum Thani"
      },
      "changwat_id": "13"
    },
    "1302": {
      "name": {
        "th": "คลองหลวง",
        "en": "khlong Luang"
      },
      "changwat_id": "13"
    },
    "1303": {
      "name": {
        "th": "ธัญบุรี",
        "en": "Thanyaburi"
      },
      "changwat_id": "13"
    },
    "1304": {
      "name": {
        "th": "หนองเสือ",
        "en": "Nong Suea"
      },
      "changwat_id": "13"
    },
    "1305": {
      "name": {
        "th": "ลาดหลุมแก้ว",
        "en": "Lat Lum Kaeo"
      },
      "changwat_id": "13"
    },
    "1306": {
      "name": {
        "th": "ลำลูกกา",
        "en": "Lam Luk Ka"
      },
      "changwat_id": "13"
    },
    "1307": {
      "name": {
        "th": "สามโคก",
        "en": "Sam Khok"
      },
      "changwat_id": "13"
    },
    "1401": {
      "name": {
        "th": "พระนครศรีอยุธยา",
        "en": "Phra Nakhon Si Ayutthaya"
      },
      "changwat_id": "14"
    },
    "1402": {
      "name": {
        "th": "ท่าเรือ",
        "en": "Tha Ruea"
      },
      "changwat_id": "14"
    },
    "1403": {
      "name": {
        "th": "นครหลวง",
        "en": "Nakhon Luang"
      },
      "changwat_id": "14"
    },
    "1404": {
      "name": {
        "th": "บางไทร",
        "en": "Bang Sai"
      },
      "changwat_id": "14"
    },
    "1405": {
      "name": {
        "th": "บางบาล",
        "en": "Bang Ban"
      },
      "changwat_id": "14"
    },
    "1406": {
      "name": {
        "th": "บางปะอิน",
        "en": "Bang Pa-in"
      },
      "changwat_id": "14"
    },
    "1407": {
      "name": {
        "th": "บางปะหัน",
        "en": "Bang Pahan"
      },
      "changwat_id": "14"
    },
    "1408": {
      "name": {
        "th": "ผักไห่",
        "en": "Phak Hai"
      },
      "changwat_id": "14"
    },
    "1409": {
      "name": {
        "th": "ภาชี",
        "en": "Phachi"
      },
      "changwat_id": "14"
    },
    "1410": {
      "name": {
        "th": "ลาดบัวหลวง",
        "en": "Lat Bua Luang"
      },
      "changwat_id": "14"
    },
    "1411": {
      "name": {
        "th": "วังน้อย",
        "en": "Wang Noi"
      },
      "changwat_id": "14"
    },
    "1412": {
      "name": {
        "th": "เสนา",
        "en": "Sena"
      },
      "changwat_id": "14"
    },
    "1413": {
      "name": {
        "th": "บางซ้าย",
        "en": "Bang Sai"
      },
      "changwat_id": "14"
    },
    "1414": {
      "name": {
        "th": "อุทัย",
        "en": "Uthai"
      },
      "changwat_id": "14"
    },
    "1415": {
      "name": {
        "th": "มหาราช",
        "en": "Maha Rat"
      },
      "changwat_id": "14"
    },
    "1416": {
      "name": {
        "th": "บ้านแพรก",
        "en": "Ban Phraek"
      },
      "changwat_id": "14"
    },
    "1501": {
      "name": {
        "th": "เมืองอ่างทอง",
        "en": "Mueang Ang Thong"
      },
      "changwat_id": "15"
    },
    "1502": {
      "name": {
        "th": "ไชโย",
        "en": "Chaiyo"
      },
      "changwat_id": "15"
    },
    "1503": {
      "name": {
        "th": "ป่าโมก",
        "en": "Pa Mok"
      },
      "changwat_id": "15"
    },
    "1504": {
      "name": {
        "th": "โพธิ์ทอง",
        "en": "Pho Thong"
      },
      "changwat_id": "15"
    },
    "1505": {
      "name": {
        "th": "แสวงหา",
        "en": "Sawaeng Ha"
      },
      "changwat_id": "15"
    },
    "1506": {
      "name": {
        "th": "วิเศษชัยชาญ",
        "en": "Wiset Chai Chan"
      },
      "changwat_id": "15"
    },
    "1507": {
      "name": {
        "th": "สามโก้",
        "en": "Samko"
      },
      "changwat_id": "15"
    },
    "1601": {
      "name": {
        "th": "เมืองลพบุรี",
        "en": "Mueang Lop Buri"
      },
      "changwat_id": "16"
    },
    "1602": {
      "name": {
        "th": "พัฒนานิคม",
        "en": "Phatthana Nikhom"
      },
      "changwat_id": "16"
    },
    "1603": {
      "name": {
        "th": "โคกสำโรง",
        "en": "Khok Samrong"
      },
      "changwat_id": "16"
    },
    "1604": {
      "name": {
        "th": "ชัยบาดาล",
        "en": "Chai Badan"
      },
      "changwat_id": "16"
    },
    "1605": {
      "name": {
        "th": "ท่าวุ้ง",
        "en": "Tha Wung"
      },
      "changwat_id": "16"
    },
    "1606": {
      "name": {
        "th": "บ้านหมี่",
        "en": "Ban Mi"
      },
      "changwat_id": "16"
    },
    "1607": {
      "name": {
        "th": "ท่าหลวง",
        "en": "Tha Luang"
      },
      "changwat_id": "16"
    },
    "1608": {
      "name": {
        "th": "สระโบสถ์",
        "en": "Sa Bot"
      },
      "changwat_id": "16"
    },
    "1609": {
      "name": {
        "th": "โคกเจริญ",
        "en": "Khok Charoen"
      },
      "changwat_id": "16"
    },
    "1610": {
      "name": {
        "th": "ลำสนธิ",
        "en": "Lam Sonthi"
      },
      "changwat_id": "16"
    },
    "1611": {
      "name": {
        "th": "หนองม่วง",
        "en": "Nong Muang"
      },
      "changwat_id": "16"
    },
    "1701": {
      "name": {
        "th": "เมืองสิงห์บุรี",
        "en": "Mueang Sing Buri"
      },
      "changwat_id": "17"
    },
    "1702": {
      "name": {
        "th": "บางระจัน",
        "en": "Bang Rachan"
      },
      "changwat_id": "17"
    },
    "1703": {
      "name": {
        "th": "ค่ายบางระจัน",
        "en": "Khai Bang Rachan"
      },
      "changwat_id": "17"
    },
    "1704": {
      "name": {
        "th": "พรหมบุรี",
        "en": "Phrom Buri"
      },
      "changwat_id": "17"
    },
    "1705": {
      "name": {
        "th": "ท่าช้าง",
        "en": "Tha Chang"
      },
      "changwat_id": "17"
    },
    "1706": {
      "name": {
        "th": "อินทร์บุรี",
        "en": "In Buri"
      },
      "changwat_id": "17"
    },
    "1801": {
      "name": {
        "th": "เมืองชัยนาท",
        "en": "Mueang Chai Nat"
      },
      "changwat_id": "18"
    },
    "1802": {
      "name": {
        "th": "มโนรมย์",
        "en": "Manorom"
      },
      "changwat_id": "18"
    },
    "1803": {
      "name": {
        "th": "วัดสิงห์",
        "en": "Wat Sing"
      },
      "changwat_id": "18"
    },
    "1804": {
      "name": {
        "th": "สรรพยา",
        "en": "Sapphaya"
      },
      "changwat_id": "18"
    },
    "1805": {
      "name": {
        "th": "สรรคบุรี",
        "en": "Sankhaburi"
      },
      "changwat_id": "18"
    },
    "1806": {
      "name": {
        "th": "หันคา",
        "en": "Hankha"
      },
      "changwat_id": "18"
    },
    "1807": {
      "name": {
        "th": "หนองมะโมง",
        "en": "Nong Mamong"
      },
      "changwat_id": "18"
    },
    "1808": {
      "name": {
        "th": "เนินขาม",
        "en": "Noen Kham"
      },
      "changwat_id": "18"
    },
    "1901": {
      "name": {
        "th": "เมืองสระบุรี",
        "en": "Mueang Saraburi"
      },
      "changwat_id": "19"
    },
    "1902": {
      "name": {
        "th": "แก่งคอย",
        "en": "Kaeng Khoi"
      },
      "changwat_id": "19"
    },
    "1903": {
      "name": {
        "th": "หนองแค",
        "en": "Nong Khae"
      },
      "changwat_id": "19"
    },
    "1904": {
      "name": {
        "th": "วิหารแดง",
        "en": "Wihan Daeng"
      },
      "changwat_id": "19"
    },
    "1905": {
      "name": {
        "th": "หนองแซง",
        "en": "Nong Saeng"
      },
      "changwat_id": "19"
    },
    "1906": {
      "name": {
        "th": "บ้านหมอ",
        "en": "Ban Mo"
      },
      "changwat_id": "19"
    },
    "1907": {
      "name": {
        "th": "ดอนพุด",
        "en": "Don Phut"
      },
      "changwat_id": "19"
    },
    "1908": {
      "name": {
        "th": "หนองโดน",
        "en": "Nong Don"
      },
      "changwat_id": "19"
    },
    "1909": {
      "name": {
        "th": "พระพุทธบาท",
        "en": "Phra Phutthabat"
      },
      "changwat_id": "19"
    },
    "1910": {
      "name": {
        "th": "เสาไห้",
        "en": "Sao Hai"
      },
      "changwat_id": "19"
    },
    "1911": {
      "name": {
        "th": "มวกเหล็ก",
        "en": "Muak Lek"
      },
      "changwat_id": "19"
    },
    "1912": {
      "name": {
        "th": "วังม่วง",
        "en": "Wang Muang"
      },
      "changwat_id": "19"
    },
    "1913": {
      "name": {
        "th": "เฉลิมพระเกียรติ",
        "en": "Chaloem Phra Kiet"
      },
      "changwat_id": "19"
    },
    "2001": {
      "name": {
        "th": "เมืองชลบุรี",
        "en": "Mueang Chon Buri"
      },
      "changwat_id": "20"
    },
    "2002": {
      "name": {
        "th": "บ้านบึง",
        "en": "Ban Bueng"
      },
      "changwat_id": "20"
    },
    "2003": {
      "name": {
        "th": "หนองใหญ่",
        "en": "Nong Yai"
      },
      "changwat_id": "20"
    },
    "2004": {
      "name": {
        "th": "บางละมุง",
        "en": "Bang Lamung"
      },
      "changwat_id": "20"
    },
    "2005": {
      "name": {
        "th": "พานทอง",
        "en": "Phan Thong"
      },
      "changwat_id": "20"
    },
    "2006": {
      "name": {
        "th": "พนัสนิคม",
        "en": "Phanat Nikhom"
      },
      "changwat_id": "20"
    },
    "2007": {
      "name": {
        "th": "ศรีราชา",
        "en": "Si Racha"
      },
      "changwat_id": "20"
    },
    "2008": {
      "name": {
        "th": "เกาะสีชัง",
        "en": "Ko Sichang"
      },
      "changwat_id": "20"
    },
    "2009": {
      "name": {
        "th": "สัตหีบ",
        "en": "Sattahip"
      },
      "changwat_id": "20"
    },
    "2010": {
      "name": {
        "th": "บ่อทอง",
        "en": "Bo Thong"
      },
      "changwat_id": "20"
    },
    "2011": {
      "name": {
        "th": "เกาะจันทร์",
        "en": "Ko Chan"
      },
      "changwat_id": "20"
    },
    "2101": {
      "name": {
        "th": "เมืองระยอง",
        "en": "Mueang Rayong"
      },
      "changwat_id": "21"
    },
    "2102": {
      "name": {
        "th": "บ้านฉาง",
        "en": "Ban Chang"
      },
      "changwat_id": "21"
    },
    "2103": {
      "name": {
        "th": "แกลง",
        "en": "Klaeng"
      },
      "changwat_id": "21"
    },
    "2104": {
      "name": {
        "th": "วังจันทร์",
        "en": "Wang Chan"
      },
      "changwat_id": "21"
    },
    "2105": {
      "name": {
        "th": "บ้านค่าย",
        "en": "Ban Khai"
      },
      "changwat_id": "21"
    },
    "2106": {
      "name": {
        "th": "ปลวกแดง",
        "en": "Pluak Daeng"
      },
      "changwat_id": "21"
    },
    "2107": {
      "name": {
        "th": "เขาชะเมา",
        "en": "Khao Chamao"
      },
      "changwat_id": "21"
    },
    "2108": {
      "name": {
        "th": "นิคมพัฒนา",
        "en": "Nikhom Phatthana"
      },
      "changwat_id": "21"
    },
    "2201": {
      "name": {
        "th": "เมืองจันทบุรี",
        "en": "Mueang Chanthaburi"
      },
      "changwat_id": "22"
    },
    "2202": {
      "name": {
        "th": "ขลุง",
        "en": "Khlung"
      },
      "changwat_id": "22"
    },
    "2203": {
      "name": {
        "th": "ท่าใหม่",
        "en": "Tha Mai"
      },
      "changwat_id": "22"
    },
    "2204": {
      "name": {
        "th": "โป่งน้ำร้อน",
        "en": "Pong Nam Ron"
      },
      "changwat_id": "22"
    },
    "2205": {
      "name": {
        "th": "มะขาม",
        "en": "Makham"
      },
      "changwat_id": "22"
    },
    "2206": {
      "name": {
        "th": "แหลมสิงห์",
        "en": "Laem Sing"
      },
      "changwat_id": "22"
    },
    "2207": {
      "name": {
        "th": "สอยดาว",
        "en": "Soi Dao"
      },
      "changwat_id": "22"
    },
    "2208": {
      "name": {
        "th": "แก่งหางแมว",
        "en": "Kaeng Hang Maeo"
      },
      "changwat_id": "22"
    },
    "2209": {
      "name": {
        "th": "นายายอาม",
        "en": "Na Yai Am"
      },
      "changwat_id": "22"
    },
    "2210": {
      "name": {
        "th": "เขาคิชฌกูฏ",
        "en": "Khao Khitchakut"
      },
      "changwat_id": "22"
    },
    "2301": {
      "name": {
        "th": "เมืองตราด",
        "en": "Mueang Trat"
      },
      "changwat_id": "23"
    },
    "2302": {
      "name": {
        "th": "คลองใหญ่",
        "en": "Khlong Yai"
      },
      "changwat_id": "23"
    },
    "2303": {
      "name": {
        "th": "เขาสมิง",
        "en": "Khao Saming"
      },
      "changwat_id": "23"
    },
    "2304": {
      "name": {
        "th": "บ่อไร่",
        "en": "Bo Rai"
      },
      "changwat_id": "23"
    },
    "2305": {
      "name": {
        "th": "แหลมงอบ",
        "en": "Laem Ngop"
      },
      "changwat_id": "23"
    },
    "2306": {
      "name": {
        "th": "เกาะกูด",
        "en": "Ko Kut"
      },
      "changwat_id": "23"
    },
    "2307": {
      "name": {
        "th": "เกาะช้าง",
        "en": "Ko Chang"
      },
      "changwat_id": "23"
    },
    "2401": {
      "name": {
        "th": "เมืองฉะเชิงเทรา",
        "en": "Mueang Chachoengsao"
      },
      "changwat_id": "24"
    },
    "2402": {
      "name": {
        "th": "บางคล้า",
        "en": "Bang Khla"
      },
      "changwat_id": "24"
    },
    "2403": {
      "name": {
        "th": "บางน้ำเปรี้ยว",
        "en": "Bang Nam Priao"
      },
      "changwat_id": "24"
    },
    "2404": {
      "name": {
        "th": "บางปะกง",
        "en": "Bang Pakong"
      },
      "changwat_id": "24"
    },
    "2405": {
      "name": {
        "th": "บ้านโพธิ์",
        "en": "Ban Pho"
      },
      "changwat_id": "24"
    },
    "2406": {
      "name": {
        "th": "พนมสารคาม",
        "en": "Phanom Sarakham"
      },
      "changwat_id": "24"
    },
    "2407": {
      "name": {
        "th": "ราชสาส์น",
        "en": "Ratchasan"
      },
      "changwat_id": "24"
    },
    "2408": {
      "name": {
        "th": "สนามชัย",
        "en": "Sanam Chai Khet"
      },
      "changwat_id": "24"
    },
    "2409": {
      "name": {
        "th": "แปลงยาว",
        "en": "Pleang Yao"
      },
      "changwat_id": "24"
    },
    "2410": {
      "name": {
        "th": "ท่าตะเกียบ",
        "en": "Tha Takiap"
      },
      "changwat_id": "24"
    },
    "2411": {
      "name": {
        "th": "คลองเขื่อน",
        "en": "Khlong Khuean"
      },
      "changwat_id": "24"
    },
    "2501": {
      "name": {
        "th": "เมืองปราจีนบุรี",
        "en": "Mueang Prachin Buri"
      },
      "changwat_id": "25"
    },
    "2502": {
      "name": {
        "th": "กบินทร์บุรี",
        "en": "Kabin Buri"
      },
      "changwat_id": "25"
    },
    "2503": {
      "name": {
        "th": "นาดี",
        "en": "Na Di"
      },
      "changwat_id": "25"
    },
    "2506": {
      "name": {
        "th": "บ้านสร้าง",
        "en": "Ban Sang"
      },
      "changwat_id": "25"
    },
    "2507": {
      "name": {
        "th": "ประจันตคาม",
        "en": "Prachantakham"
      },
      "changwat_id": "25"
    },
    "2508": {
      "name": {
        "th": "ศรีมหาโพธิ",
        "en": "Si Maha Phot"
      },
      "changwat_id": "25"
    },
    "2509": {
      "name": {
        "th": "ศรีมโหสถ",
        "en": "Si Mahosot"
      },
      "changwat_id": "25"
    },
    "2601": {
      "name": {
        "th": "เมืองนครนายก",
        "en": "Mueang Nakhon Nayok"
      },
      "changwat_id": "26"
    },
    "2602": {
      "name": {
        "th": "ปากพลี",
        "en": "Pak Phli"
      },
      "changwat_id": "26"
    },
    "2603": {
      "name": {
        "th": "บ้านนา",
        "en": "Ban Na"
      },
      "changwat_id": "26"
    },
    "2604": {
      "name": {
        "th": "องครักษ์",
        "en": "Ongkharak"
      },
      "changwat_id": "26"
    },
    "2701": {
      "name": {
        "th": "เมืองสระแก้ว",
        "en": "Mueang Sa Kaeo"
      },
      "changwat_id": "27"
    },
    "2702": {
      "name": {
        "th": "คลองหาด",
        "en": "Khlong Hat"
      },
      "changwat_id": "27"
    },
    "2703": {
      "name": {
        "th": "ตาพระยา",
        "en": "Ta Phraya"
      },
      "changwat_id": "27"
    },
    "2704": {
      "name": {
        "th": "วังน้ำเย็น",
        "en": "Wang Nam Yen"
      },
      "changwat_id": "27"
    },
    "2705": {
      "name": {
        "th": "วัฒนานคร",
        "en": "Watthana Nakhon"
      },
      "changwat_id": "27"
    },
    "2706": {
      "name": {
        "th": "อรัญประเทศ",
        "en": "Aranyaprathet"
      },
      "changwat_id": "27"
    },
    "2707": {
      "name": {
        "th": "เขาฉกรรจ์",
        "en": "Khao Chakan"
      },
      "changwat_id": "27"
    },
    "2708": {
      "name": {
        "th": "โคกสูง",
        "en": "Khok Sung"
      },
      "changwat_id": "27"
    },
    "2709": {
      "name": {
        "th": "วังสมบูรณ์",
        "en": "Wang Sombun"
      },
      "changwat_id": "27"
    },
    "3001": {
      "name": {
        "th": "เมืองนครราชสีมา",
        "en": "Mueang Nakhon Ratchasima"
      },
      "changwat_id": "30"
    },
    "3002": {
      "name": {
        "th": "ครบุรี",
        "en": "Khon Buri"
      },
      "changwat_id": "30"
    },
    "3003": {
      "name": {
        "th": "เสิงสาง",
        "en": "Soeng Sang"
      },
      "changwat_id": "30"
    },
    "3004": {
      "name": {
        "th": "คง",
        "en": "Khong"
      },
      "changwat_id": "30"
    },
    "3005": {
      "name": {
        "th": "บ้านเหลื่อม",
        "en": "Ban Lueam"
      },
      "changwat_id": "30"
    },
    "3006": {
      "name": {
        "th": "จักราช",
        "en": "Chakkarat"
      },
      "changwat_id": "30"
    },
    "3007": {
      "name": {
        "th": "โชคชัย",
        "en": "Chok Chai"
      },
      "changwat_id": "30"
    },
    "3008": {
      "name": {
        "th": "ด่านขุนทด",
        "en": "Dan Khun Thot"
      },
      "changwat_id": "30"
    },
    "3009": {
      "name": {
        "th": "โนนไทย",
        "en": "Non Thai"
      },
      "changwat_id": "30"
    },
    "3010": {
      "name": {
        "th": "โนนสูง",
        "en": "Non Sung"
      },
      "changwat_id": "30"
    },
    "3011": {
      "name": {
        "th": "ขามสะแกแสง",
        "en": "Kham Sakaesaeng"
      },
      "changwat_id": "30"
    },
    "3012": {
      "name": {
        "th": "บัวใหญ่",
        "en": "Bua Yai"
      },
      "changwat_id": "30"
    },
    "3013": {
      "name": {
        "th": "ประทาย",
        "en": "Prathai"
      },
      "changwat_id": "30"
    },
    "3014": {
      "name": {
        "th": "ปักธงชัย",
        "en": "Pak Thong Chai"
      },
      "changwat_id": "30"
    },
    "3015": {
      "name": {
        "th": "พิมาย",
        "en": "Phimai"
      },
      "changwat_id": "30"
    },
    "3016": {
      "name": {
        "th": "ห้วยแถลง",
        "en": "Huai Thalaeng"
      },
      "changwat_id": "30"
    },
    "3017": {
      "name": {
        "th": "ชุมพวง",
        "en": "Chum Phuang"
      },
      "changwat_id": "30"
    },
    "3018": {
      "name": {
        "th": "สูงเนิน",
        "en": "Sung Noen"
      },
      "changwat_id": "30"
    },
    "3019": {
      "name": {
        "th": "ขามทะเลสอ",
        "en": "Kham Thale So"
      },
      "changwat_id": "30"
    },
    "3020": {
      "name": {
        "th": "สีคิ้ว",
        "en": "Si Khio"
      },
      "changwat_id": "30"
    },
    "3021": {
      "name": {
        "th": "ปากช่อง",
        "en": "Pak Chong"
      },
      "changwat_id": "30"
    },
    "3022": {
      "name": {
        "th": "หนองบุญมาก",
        "en": "Nong Bunmak"
      },
      "changwat_id": "30"
    },
    "3023": {
      "name": {
        "th": "แก้งสนามนาง",
        "en": "Kaeng Sanam Nang"
      },
      "changwat_id": "30"
    },
    "3024": {
      "name": {
        "th": "โนนแดง",
        "en": "Non Daeng"
      },
      "changwat_id": "30"
    },
    "3025": {
      "name": {
        "th": "วังน้ำเขียว",
        "en": "Wang Nam Khiao"
      },
      "changwat_id": "30"
    },
    "3026": {
      "name": {
        "th": "เทพารักษ์",
        "en": "Thepharak"
      },
      "changwat_id": "30"
    },
    "3027": {
      "name": {
        "th": "เมืองยาง",
        "en": "Mueang Yang"
      },
      "changwat_id": "30"
    },
    "3028": {
      "name": {
        "th": "พระทองคำ",
        "en": "Phra Thong Kham"
      },
      "changwat_id": "30"
    },
    "3029": {
      "name": {
        "th": "ลำทะเมนชัย",
        "en": "Lamtaman Chai"
      },
      "changwat_id": "30"
    },
    "3030": {
      "name": {
        "th": "บัวลาย",
        "en": "Bua Lai"
      },
      "changwat_id": "30"
    },
    "3031": {
      "name": {
        "th": "สีดา",
        "en": "Sida"
      },
      "changwat_id": "30"
    },
    "3032": {
      "name": {
        "th": "เฉลิมพระเกียรติ",
        "en": "Chaloem Phra Kiet"
      },
      "changwat_id": "30"
    },
    "3101": {
      "name": {
        "th": "เมืองบุรีรัมย์",
        "en": "Mueang Buri Ram"
      },
      "changwat_id": "31"
    },
    "3102": {
      "name": {
        "th": "คูเมือง",
        "en": "Khu Mueang"
      },
      "changwat_id": "31"
    },
    "3103": {
      "name": {
        "th": "กระสัง",
        "en": "Krasang"
      },
      "changwat_id": "31"
    },
    "3104": {
      "name": {
        "th": "นางรอง",
        "en": "Nang Rong"
      },
      "changwat_id": "31"
    },
    "3105": {
      "name": {
        "th": "หนองกี่",
        "en": "Nong Ki"
      },
      "changwat_id": "31"
    },
    "3106": {
      "name": {
        "th": "ละหานทราย",
        "en": "Lahan Sai"
      },
      "changwat_id": "31"
    },
    "3107": {
      "name": {
        "th": "ประโคนชัย",
        "en": "Prakhon Chai"
      },
      "changwat_id": "31"
    },
    "3108": {
      "name": {
        "th": "บ้านกรวด",
        "en": "Ban Kruat"
      },
      "changwat_id": "31"
    },
    "3109": {
      "name": {
        "th": "พุทไธสง",
        "en": "Phutthaisong"
      },
      "changwat_id": "31"
    },
    "3110": {
      "name": {
        "th": "ลำปลายมาศ",
        "en": "Lam Plai Mat"
      },
      "changwat_id": "31"
    },
    "3111": {
      "name": {
        "th": "สตึก",
        "en": "Satuek"
      },
      "changwat_id": "31"
    },
    "3112": {
      "name": {
        "th": "ปะคำ",
        "en": "Pakham"
      },
      "changwat_id": "31"
    },
    "3113": {
      "name": {
        "th": "นาโพธิ์",
        "en": "Na Pho"
      },
      "changwat_id": "31"
    },
    "3114": {
      "name": {
        "th": "หนองหงส์",
        "en": "Nong Hong"
      },
      "changwat_id": "31"
    },
    "3115": {
      "name": {
        "th": "พลับพลาชัย",
        "en": "Phlapphla Chai"
      },
      "changwat_id": "31"
    },
    "3116": {
      "name": {
        "th": "ห้วยราช",
        "en": "Huai Rat"
      },
      "changwat_id": "31"
    },
    "3117": {
      "name": {
        "th": "โนนสุวรรณ",
        "en": "Non Din Daeng"
      },
      "changwat_id": "31"
    },
    "3118": {
      "name": {
        "th": "ชำนิ",
        "en": "Chamni"
      },
      "changwat_id": "31"
    },
    "3119": {
      "name": {
        "th": "บ้านใหม่ไชยพจน์",
        "en": "Ban Mai Chaiyaphot"
      },
      "changwat_id": "31"
    },
    "3120": {
      "name": {
        "th": "โนนดินแดง",
        "en": "Non Din Daeng"
      },
      "changwat_id": "31"
    },
    "3121": {
      "name": {
        "th": "บ้านด่าน",
        "en": "Ban Dan"
      },
      "changwat_id": "31"
    },
    "3122": {
      "name": {
        "th": "แคนดง",
        "en": "Khaen Dong"
      },
      "changwat_id": "31"
    },
    "3123": {
      "name": {
        "th": "เฉลิมพระเกียรติ",
        "en": "Chaloem Phra Kiet"
      },
      "changwat_id": "31"
    },
    "3201": {
      "name": {
        "th": "เมืองสุรินทร์",
        "en": "Mueang Surin"
      },
      "changwat_id": "32"
    },
    "3202": {
      "name": {
        "th": "ชุมพลบุรี",
        "en": "Chumphon Buri"
      },
      "changwat_id": "32"
    },
    "3203": {
      "name": {
        "th": "ท่าตูม",
        "en": "Tha Tum"
      },
      "changwat_id": "32"
    },
    "3204": {
      "name": {
        "th": "จอมพระ",
        "en": "Chom Phra"
      },
      "changwat_id": "32"
    },
    "3205": {
      "name": {
        "th": "ปราสาท",
        "en": "Prasat"
      },
      "changwat_id": "32"
    },
    "3206": {
      "name": {
        "th": "กาบเชิง",
        "en": "Kap Choeng"
      },
      "changwat_id": "32"
    },
    "3207": {
      "name": {
        "th": "รัตนบุรี",
        "en": "Rattanaburi"
      },
      "changwat_id": "32"
    },
    "3208": {
      "name": {
        "th": "สนม",
        "en": "Sanom"
      },
      "changwat_id": "32"
    },
    "3209": {
      "name": {
        "th": "ศีขรภูมิ",
        "en": "Sikhoraphum"
      },
      "changwat_id": "32"
    },
    "3210": {
      "name": {
        "th": "สังขะ",
        "en": "Sangkha"
      },
      "changwat_id": "32"
    },
    "3211": {
      "name": {
        "th": "ลำดวน",
        "en": "Lamduan"
      },
      "changwat_id": "32"
    },
    "3212": {
      "name": {
        "th": "สำโรงทาบ",
        "en": "Samrong Thap"
      },
      "changwat_id": "32"
    },
    "3213": {
      "name": {
        "th": "บัวเชด",
        "en": "Buachet"
      },
      "changwat_id": "32"
    },
    "3214": {
      "name": {
        "th": "พนมดงรัก",
        "en": "Phanom Dong Rak"
      },
      "changwat_id": "32"
    },
    "3215": {
      "name": {
        "th": "ศรีณรงค์",
        "en": "Si Narong"
      },
      "changwat_id": "32"
    },
    "3216": {
      "name": {
        "th": "เขวาสินรินทร์",
        "en": "Khwao Sinarin"
      },
      "changwat_id": "32"
    },
    "3217": {
      "name": {
        "th": "โนนนารายณ์",
        "en": "Non Narai"
      },
      "changwat_id": "32"
    },
    "3301": {
      "name": {
        "th": "เมืองศรีสะเกษ",
        "en": "Mueang Si Sa Ket"
      },
      "changwat_id": "33"
    },
    "3302": {
      "name": {
        "th": "ยางชุมน้อย",
        "en": "Yang Chum Noi"
      },
      "changwat_id": "33"
    },
    "3303": {
      "name": {
        "th": "กันทรารมย์",
        "en": "Kanthararom"
      },
      "changwat_id": "33"
    },
    "3304": {
      "name": {
        "th": "กันทรลักษ์",
        "en": "Kantharalak"
      },
      "changwat_id": "33"
    },
    "3305": {
      "name": {
        "th": "ขุขันธ์",
        "en": "Khukhan"
      },
      "changwat_id": "33"
    },
    "3306": {
      "name": {
        "th": "ไพรบึง",
        "en": "Phrai Bueng"
      },
      "changwat_id": "33"
    },
    "3307": {
      "name": {
        "th": "ปรางค์กู่",
        "en": "Prang Ku"
      },
      "changwat_id": "33"
    },
    "3308": {
      "name": {
        "th": "ขุนหาญ",
        "en": "Khun Han"
      },
      "changwat_id": "33"
    },
    "3309": {
      "name": {
        "th": "ราษีไศล",
        "en": "Rasi Salai"
      },
      "changwat_id": "33"
    },
    "3310": {
      "name": {
        "th": "อุทุมพรพิสัย",
        "en": "Uthumphon Phisai"
      },
      "changwat_id": "33"
    },
    "3311": {
      "name": {
        "th": "บึงบูรพ์",
        "en": "Bueng Bun"
      },
      "changwat_id": "33"
    },
    "3312": {
      "name": {
        "th": "ห้วยทับทัน",
        "en": "Huai Thap Than"
      },
      "changwat_id": "33"
    },
    "3313": {
      "name": {
        "th": "โนนคูณ",
        "en": "Non Khun"
      },
      "changwat_id": "33"
    },
    "3314": {
      "name": {
        "th": "ศรีรัตนะ",
        "en": "Si Rattana"
      },
      "changwat_id": "33"
    },
    "3315": {
      "name": {
        "th": "น้ำเกลี้ยง",
        "en": "Nam Kliang"
      },
      "changwat_id": "33"
    },
    "3316": {
      "name": {
        "th": "วังหิน",
        "en": "Wang Hin"
      },
      "changwat_id": "33"
    },
    "3317": {
      "name": {
        "th": "ภูสิงห์",
        "en": "Phu Sing"
      },
      "changwat_id": "33"
    },
    "3318": {
      "name": {
        "th": "เมืองจันทร์",
        "en": "Mueang Chan"
      },
      "changwat_id": "33"
    },
    "3319": {
      "name": {
        "th": "เบญจลักษ์",
        "en": "Benchalak"
      },
      "changwat_id": "33"
    },
    "3320": {
      "name": {
        "th": "พยุห์",
        "en": "Phayu"
      },
      "changwat_id": "33"
    },
    "3321": {
      "name": {
        "th": "โพธิ์ศรีสุวรรณ",
        "en": "Pho Si Suwan"
      },
      "changwat_id": "33"
    },
    "3322": {
      "name": {
        "th": "ศิลาลาด",
        "en": "Sila Lat"
      },
      "changwat_id": "33"
    },
    "3401": {
      "name": {
        "th": "เมืองอุบลราชธานี",
        "en": "Mueang Ubon Ratchathani"
      },
      "changwat_id": "34"
    },
    "3402": {
      "name": {
        "th": "ศรีเมืองใหม่",
        "en": "Si Mueang Mai"
      },
      "changwat_id": "34"
    },
    "3403": {
      "name": {
        "th": "โขงเจียม",
        "en": "Khong Chiam"
      },
      "changwat_id": "34"
    },
    "3404": {
      "name": {
        "th": "เขื่องใน",
        "en": "Khueang Nai"
      },
      "changwat_id": "34"
    },
    "3405": {
      "name": {
        "th": "เขมราฐ",
        "en": "Khemarat"
      },
      "changwat_id": "34"
    },
    "3407": {
      "name": {
        "th": "เดชอุดม",
        "en": "Det Udom"
      },
      "changwat_id": "34"
    },
    "3408": {
      "name": {
        "th": "นาจะหลวย",
        "en": "Na Chaluai"
      },
      "changwat_id": "34"
    },
    "3409": {
      "name": {
        "th": "น้ำยืน",
        "en": "Nam Yuen"
      },
      "changwat_id": "34"
    },
    "3410": {
      "name": {
        "th": "บุณฑริก",
        "en": "Boontharik"
      },
      "changwat_id": "34"
    },
    "3411": {
      "name": {
        "th": "ตระการพืชผล",
        "en": "Trakan Phuet Phon"
      },
      "changwat_id": "34"
    },
    "3412": {
      "name": {
        "th": "กุดข้าวปุ้น",
        "en": "Kut Khaopun"
      },
      "changwat_id": "34"
    },
    "3414": {
      "name": {
        "th": "ม่วงสามสิบ",
        "en": "Muang Sam Sip"
      },
      "changwat_id": "34"
    },
    "3415": {
      "name": {
        "th": "วารินชำราบ",
        "en": "Warin Chamrap"
      },
      "changwat_id": "34"
    },
    "3419": {
      "name": {
        "th": "พิบูลมังสาหาร",
        "en": "Phibun Mangsahan"
      },
      "changwat_id": "34"
    },
    "3420": {
      "name": {
        "th": "ตาลสุม",
        "en": "Tan Sum"
      },
      "changwat_id": "34"
    },
    "3421": {
      "name": {
        "th": "โพธิ์ไทร",
        "en": "Pho Sai"
      },
      "changwat_id": "34"
    },
    "3422": {
      "name": {
        "th": "สำโรง",
        "en": "Samrong"
      },
      "changwat_id": "34"
    },
    "3424": {
      "name": {
        "th": "ดอนมดแดง",
        "en": "Don Mot Daeng"
      },
      "changwat_id": "34"
    },
    "3425": {
      "name": {
        "th": "สิรินธร",
        "en": "Sirindhorn"
      },
      "changwat_id": "34"
    },
    "3426": {
      "name": {
        "th": "ทุ่งศรีอุดม",
        "en": "Thung Si Udom"
      },
      "changwat_id": "34"
    },
    "3429": {
      "name": {
        "th": "นาเยีย",
        "en": "Na Yia"
      },
      "changwat_id": "34"
    },
    "3430": {
      "name": {
        "th": "นาตาล",
        "en": "Na Tan"
      },
      "changwat_id": "34"
    },
    "3431": {
      "name": {
        "th": "เหล่าเสือโก้ก",
        "en": "Lao Suea Kok"
      },
      "changwat_id": "34"
    },
    "3432": {
      "name": {
        "th": "สว่างวีระวงศ์",
        "en": "Sawang Wirawong"
      },
      "changwat_id": "34"
    },
    "3433": {
      "name": {
        "th": "น้ำขุ่น",
        "en": "Nam Khun"
      },
      "changwat_id": "34"
    },
    "3501": {
      "name": {
        "th": "เมืองยโสธร",
        "en": "Mueang Yasothon"
      },
      "changwat_id": "35"
    },
    "3502": {
      "name": {
        "th": "ทรายมูล",
        "en": "Sai Mun"
      },
      "changwat_id": "35"
    },
    "3503": {
      "name": {
        "th": "กุดชุม",
        "en": "Kut Chum"
      },
      "changwat_id": "35"
    },
    "3504": {
      "name": {
        "th": "คำเขื่อนแก้ว",
        "en": "Kham Khuean Kaeo"
      },
      "changwat_id": "35"
    },
    "3505": {
      "name": {
        "th": "ป่าติ้ว",
        "en": "Pa Tio"
      },
      "changwat_id": "35"
    },
    "3506": {
      "name": {
        "th": "มหาชนะชัย",
        "en": "Maha Chana Chai"
      },
      "changwat_id": "35"
    },
    "3507": {
      "name": {
        "th": "ค้อวัง",
        "en": "Kho Wang"
      },
      "changwat_id": "35"
    },
    "3508": {
      "name": {
        "th": "เลิงนกทา",
        "en": "Loeng Nok Tha"
      },
      "changwat_id": "35"
    },
    "3509": {
      "name": {
        "th": "ไทยเจริญ",
        "en": "Thai Charoen"
      },
      "changwat_id": "35"
    },
    "3601": {
      "name": {
        "th": "เมืองชัยภูมิ",
        "en": "Mueang Chaiyaphum"
      },
      "changwat_id": "36"
    },
    "3602": {
      "name": {
        "th": "บ้านเขว้า",
        "en": "Ban Khwao"
      },
      "changwat_id": "36"
    },
    "3603": {
      "name": {
        "th": "คอนสวรรค์",
        "en": "Khon Sawan"
      },
      "changwat_id": "36"
    },
    "3604": {
      "name": {
        "th": "เกษตรสมบูรณ์",
        "en": "Kaset Sombun"
      },
      "changwat_id": "36"
    },
    "3605": {
      "name": {
        "th": "หนองบัวแดง",
        "en": "Nong Bua Daeng"
      },
      "changwat_id": "36"
    },
    "3606": {
      "name": {
        "th": "จัตุรัส",
        "en": "Chatturat"
      },
      "changwat_id": "36"
    },
    "3607": {
      "name": {
        "th": "บำเหน็จณรงค์",
        "en": "Bamnet Narong"
      },
      "changwat_id": "36"
    },
    "3608": {
      "name": {
        "th": "หนองบัวระเหว",
        "en": "Nong Bua Rawe"
      },
      "changwat_id": "36"
    },
    "3609": {
      "name": {
        "th": "เทพสถิต",
        "en": "Thep Sathit"
      },
      "changwat_id": "36"
    },
    "3610": {
      "name": {
        "th": "ภูเขียว",
        "en": "Phu Khiao"
      },
      "changwat_id": "36"
    },
    "3611": {
      "name": {
        "th": "บ้านแท่น",
        "en": "Ban Thaen"
      },
      "changwat_id": "36"
    },
    "3612": {
      "name": {
        "th": "แก้งคร้อ",
        "en": "Kaeng Khro"
      },
      "changwat_id": "36"
    },
    "3613": {
      "name": {
        "th": "คอนสาร",
        "en": "Khon San"
      },
      "changwat_id": "36"
    },
    "3614": {
      "name": {
        "th": "ภักดีชุมพล",
        "en": "Phakdi Chumphon"
      },
      "changwat_id": "36"
    },
    "3615": {
      "name": {
        "th": "เนินสง่า",
        "en": "Noen Sa-nga"
      },
      "changwat_id": "36"
    },
    "3616": {
      "name": {
        "th": "ซับใหญ่",
        "en": "Sap Yai"
      },
      "changwat_id": "36"
    },
    "3701": {
      "name": {
        "th": "เมืองอำนาจเจริญ",
        "en": "Mueang Amnat Charoen"
      },
      "changwat_id": "37"
    },
    "3702": {
      "name": {
        "th": "ชานุมาน",
        "en": "Chanuman"
      },
      "changwat_id": "37"
    },
    "3703": {
      "name": {
        "th": "ปทุมราชวงศา",
        "en": "Pathum Ratchawongsa"
      },
      "changwat_id": "37"
    },
    "3704": {
      "name": {
        "th": "พนา",
        "en": "Phana"
      },
      "changwat_id": "37"
    },
    "3705": {
      "name": {
        "th": "เสนางคนิคม",
        "en": "Senangkhanikhom"
      },
      "changwat_id": "37"
    },
    "3706": {
      "name": {
        "th": "หัวตะพาน",
        "en": "Hua Taphan"
      },
      "changwat_id": "37"
    },
    "3707": {
      "name": {
        "th": "ลืออำนาจ",
        "en": "Lue Amnat"
      },
      "changwat_id": "37"
    },
    "3801": {
      "name": {
        "th": "บึงกาฬ",
        "en": "Bueng Kan"
      },
      "changwat_id": "38"
    },
    "3802": {
      "name": {
        "th": "พรเจริญ",
        "en": "Phon Charoen"
      },
      "changwat_id": "38"
    },
    "3803": {
      "name": {
        "th": "โซ่พิสัย",
        "en": "So Phisai"
      },
      "changwat_id": "38"
    },
    "3804": {
      "name": {
        "th": "เซกา",
        "en": "Seka"
      },
      "changwat_id": "38"
    },
    "3805": {
      "name": {
        "th": "ปากคาด",
        "en": "Pak Khat"
      },
      "changwat_id": "38"
    },
    "3806": {
      "name": {
        "th": "บึงโขงหลง",
        "en": "Bueng Khong Long"
      },
      "changwat_id": "38"
    },
    "3807": {
      "name": {
        "th": "ศรีวิไล",
        "en": "Si Wilai"
      },
      "changwat_id": "38"
    },
    "3808": {
      "name": {
        "th": "บุ่งคล้า",
        "en": "Bung Khla"
      },
      "changwat_id": "38"
    },
    "3901": {
      "name": {
        "th": "เมืองหนองบัวลำภู",
        "en": "Mueang Nong Bua Lam Phu"
      },
      "changwat_id": "39"
    },
    "3902": {
      "name": {
        "th": "นากลาง",
        "en": "Na Klang"
      },
      "changwat_id": "39"
    },
    "3903": {
      "name": {
        "th": "โนนสัง",
        "en": "Non Sang"
      },
      "changwat_id": "39"
    },
    "3904": {
      "name": {
        "th": "ศรีบุญเรือง",
        "en": "Si Bun Rueang"
      },
      "changwat_id": "39"
    },
    "3905": {
      "name": {
        "th": "สุวรรณคูหา",
        "en": "Suwannakhuha"
      },
      "changwat_id": "39"
    },
    "3906": {
      "name": {
        "th": "นาวัง",
        "en": "Na Wang"
      },
      "changwat_id": "39"
    },
    "4001": {
      "name": {
        "th": "เมืองขอนแก่น",
        "en": "Mueang Khon Kaen"
      },
      "changwat_id": "40"
    },
    "4002": {
      "name": {
        "th": "บ้านฝาง",
        "en": "Ban Fang"
      },
      "changwat_id": "40"
    },
    "4003": {
      "name": {
        "th": "พระยืน",
        "en": "Phra Yuen"
      },
      "changwat_id": "40"
    },
    "4004": {
      "name": {
        "th": "หนองเรือ",
        "en": "Nong Ruea"
      },
      "changwat_id": "40"
    },
    "4005": {
      "name": {
        "th": "ชุมแพ",
        "en": "Chum Phae"
      },
      "changwat_id": "40"
    },
    "4006": {
      "name": {
        "th": "สีชมพู",
        "en": "Si Chomphu"
      },
      "changwat_id": "40"
    },
    "4007": {
      "name": {
        "th": "น้ำพอง",
        "en": "Nam Phong"
      },
      "changwat_id": "40"
    },
    "4008": {
      "name": {
        "th": "อุบลรัตน์",
        "en": "Ubolratana"
      },
      "changwat_id": "40"
    },
    "4009": {
      "name": {
        "th": "กระนวน",
        "en": "Kranuan"
      },
      "changwat_id": "40"
    },
    "4010": {
      "name": {
        "th": "บ้านไผ่",
        "en": "Ban Phai"
      },
      "changwat_id": "40"
    },
    "4011": {
      "name": {
        "th": "เปือยน้อย",
        "en": "Pueai Noi"
      },
      "changwat_id": "40"
    },
    "4012": {
      "name": {
        "th": "พล",
        "en": "Phon"
      },
      "changwat_id": "40"
    },
    "4013": {
      "name": {
        "th": "แวงใหญ่",
        "en": "Waeng Yai"
      },
      "changwat_id": "40"
    },
    "4014": {
      "name": {
        "th": "แวงน้อย",
        "en": "Waeng Noi"
      },
      "changwat_id": "40"
    },
    "4015": {
      "name": {
        "th": "หนองสองห้อง",
        "en": "Nong Song Hong"
      },
      "changwat_id": "40"
    },
    "4016": {
      "name": {
        "th": "ภูเวียง",
        "en": "Phu Wiang"
      },
      "changwat_id": "40"
    },
    "4017": {
      "name": {
        "th": "มัญจาคีรี",
        "en": "Mancha Khiri"
      },
      "changwat_id": "40"
    },
    "4018": {
      "name": {
        "th": "ชนบท",
        "en": "Chonnabot"
      },
      "changwat_id": "40"
    },
    "4019": {
      "name": {
        "th": "เขาสวนกวาง",
        "en": "Khao Suan Kwang"
      },
      "changwat_id": "40"
    },
    "4020": {
      "name": {
        "th": "ภูผาม่าน",
        "en": "Phu Phaman"
      },
      "changwat_id": "40"
    },
    "4021": {
      "name": {
        "th": "ซำสูง",
        "en": "Sam Sung"
      },
      "changwat_id": "40"
    },
    "4022": {
      "name": {
        "th": "โคกโพธิ์ไชย",
        "en": "Khok Pho Chai"
      },
      "changwat_id": "40"
    },
    "4023": {
      "name": {
        "th": "หนองนาคำ",
        "en": "Nong Na Kham"
      },
      "changwat_id": "40"
    },
    "4024": {
      "name": {
        "th": "บ้านแฮด",
        "en": "Ban Haet"
      },
      "changwat_id": "40"
    },
    "4025": {
      "name": {
        "th": "โนนศิลา",
        "en": "Non Sila"
      },
      "changwat_id": "40"
    },
    "4026": {
      "name": {
        "th": "เวียงเก่า",
        "en": "Wiang Kao"
      },
      "changwat_id": "40"
    },
    "4101": {
      "name": {
        "th": "เมืองอุดรธานี",
        "en": "Mueang Udon Thani"
      },
      "changwat_id": "41"
    },
    "4102": {
      "name": {
        "th": "กุดจับ",
        "en": "Kut Chap"
      },
      "changwat_id": "41"
    },
    "4103": {
      "name": {
        "th": "หนองวัวซอ",
        "en": "Nong Wua So"
      },
      "changwat_id": "41"
    },
    "4104": {
      "name": {
        "th": "กุมภวาปี",
        "en": "Kumphawapi"
      },
      "changwat_id": "41"
    },
    "4105": {
      "name": {
        "th": "โนนสะอาด",
        "en": "Non Sa-at"
      },
      "changwat_id": "41"
    },
    "4106": {
      "name": {
        "th": "หนองหาน",
        "en": "Nong Saeng"
      },
      "changwat_id": "41"
    },
    "4107": {
      "name": {
        "th": "ทุ่งฝน",
        "en": "Thung Fon"
      },
      "changwat_id": "41"
    },
    "4108": {
      "name": {
        "th": "ไชยวาน",
        "en": "Chai Wan"
      },
      "changwat_id": "41"
    },
    "4109": {
      "name": {
        "th": "ศรีธาตุ",
        "en": "Si That"
      },
      "changwat_id": "41"
    },
    "4110": {
      "name": {
        "th": "วังสามหมอ",
        "en": "Wang Sam Mo"
      },
      "changwat_id": "41"
    },
    "4111": {
      "name": {
        "th": "บ้านดุง",
        "en": "Ban Dung"
      },
      "changwat_id": "41"
    },
    "4117": {
      "name": {
        "th": "บ้านผือ",
        "en": "Ban Phue"
      },
      "changwat_id": "41"
    },
    "4118": {
      "name": {
        "th": "น้ำโสม",
        "en": "Nam Som"
      },
      "changwat_id": "41"
    },
    "4119": {
      "name": {
        "th": "เพ็ญ",
        "en": "Phen"
      },
      "changwat_id": "41"
    },
    "4120": {
      "name": {
        "th": "สร้างคอม",
        "en": "Sang Khom"
      },
      "changwat_id": "41"
    },
    "4121": {
      "name": {
        "th": "หนองแสง",
        "en": "Nong Saeng"
      },
      "changwat_id": "41"
    },
    "4122": {
      "name": {
        "th": "นายูง",
        "en": "Na Yung"
      },
      "changwat_id": "41"
    },
    "4123": {
      "name": {
        "th": "พิบูลย์รักษ์",
        "en": "Phibun Rak"
      },
      "changwat_id": "41"
    },
    "4124": {
      "name": {
        "th": "กู่แก้ว",
        "en": "Ku Kaeo"
      },
      "changwat_id": "41"
    },
    "4125": {
      "name": {
        "th": "ประจักษ์ศิลปาคม",
        "en": "Prachaksinlapakhom"
      },
      "changwat_id": "41"
    },
    "4201": {
      "name": {
        "th": "เมืองเลย",
        "en": "Mueang Loei"
      },
      "changwat_id": "42"
    },
    "4202": {
      "name": {
        "th": "นาด้วง",
        "en": "Na Duang"
      },
      "changwat_id": "42"
    },
    "4203": {
      "name": {
        "th": "เชียงคาน",
        "en": "Chiang Khan"
      },
      "changwat_id": "42"
    },
    "4204": {
      "name": {
        "th": "ปากชม",
        "en": "Pak Chom"
      },
      "changwat_id": "42"
    },
    "4205": {
      "name": {
        "th": "ด่านซ้าย",
        "en": "Dan Sai"
      },
      "changwat_id": "42"
    },
    "4206": {
      "name": {
        "th": "นาแห้ว",
        "en": "Na Haeo"
      },
      "changwat_id": "42"
    },
    "4207": {
      "name": {
        "th": "ภูเรือ",
        "en": "Phu Ruea"
      },
      "changwat_id": "42"
    },
    "4208": {
      "name": {
        "th": "ท่าลี่",
        "en": "Tha Li"
      },
      "changwat_id": "42"
    },
    "4209": {
      "name": {
        "th": "วังสะพุง",
        "en": "Wang Saphung"
      },
      "changwat_id": "42"
    },
    "4210": {
      "name": {
        "th": "ภูกระดึง",
        "en": "Phu Kradueng"
      },
      "changwat_id": "42"
    },
    "4211": {
      "name": {
        "th": "ภูหลวง",
        "en": "Phu Luang"
      },
      "changwat_id": "42"
    },
    "4212": {
      "name": {
        "th": "ผาขาว",
        "en": "Pha  Khao"
      },
      "changwat_id": "42"
    },
    "4213": {
      "name": {
        "th": "เอราวัณ",
        "en": "Erawan"
      },
      "changwat_id": "42"
    },
    "4214": {
      "name": {
        "th": "หนองหิน",
        "en": "Nong Hin"
      },
      "changwat_id": "42"
    },
    "4301": {
      "name": {
        "th": "เมืองหนองคาย",
        "en": "Mueang Nong Khai"
      },
      "changwat_id": "43"
    },
    "4302": {
      "name": {
        "th": "ท่าบ่อ",
        "en": "Tha Bo"
      },
      "changwat_id": "43"
    },
    "4305": {
      "name": {
        "th": "โพนพิสัย",
        "en": "Phon Phisai"
      },
      "changwat_id": "43"
    },
    "4307": {
      "name": {
        "th": "ศรีเชียงใหม่",
        "en": "Si Chiang Mai"
      },
      "changwat_id": "43"
    },
    "4308": {
      "name": {
        "th": "สังคม",
        "en": "Sangkhom"
      },
      "changwat_id": "43"
    },
    "4314": {
      "name": {
        "th": "สระใคร",
        "en": "Sakhrai"
      },
      "changwat_id": "43"
    },
    "4315": {
      "name": {
        "th": "เฝ้าไร่",
        "en": "Fao Rai"
      },
      "changwat_id": "43"
    },
    "4316": {
      "name": {
        "th": "รัตนวาปี",
        "en": "Rattanawapi"
      },
      "changwat_id": "43"
    },
    "4317": {
      "name": {
        "th": "โพธิ์ตาก",
        "en": "Pho Tak"
      },
      "changwat_id": "43"
    },
    "4401": {
      "name": {
        "th": "เมืองมหาสารคาม",
        "en": "Mueang Maha Sarakham"
      },
      "changwat_id": "44"
    },
    "4402": {
      "name": {
        "th": "แกดำ",
        "en": "Kae Dam"
      },
      "changwat_id": "44"
    },
    "4403": {
      "name": {
        "th": "โกสุมพิสัย",
        "en": "Kosum Phisai"
      },
      "changwat_id": "44"
    },
    "4404": {
      "name": {
        "th": "กันทรวิชัย",
        "en": "Kantharawichai"
      },
      "changwat_id": "44"
    },
    "4405": {
      "name": {
        "th": "เชียงยืน",
        "en": "Chiang Yuen"
      },
      "changwat_id": "44"
    },
    "4406": {
      "name": {
        "th": "บรบือ",
        "en": "Borabue"
      },
      "changwat_id": "44"
    },
    "4407": {
      "name": {
        "th": "นาเชือก",
        "en": "Na Chueak"
      },
      "changwat_id": "44"
    },
    "4408": {
      "name": {
        "th": "พยัคฆภูมิพิสัย",
        "en": "Phayakkhaphum Phisai"
      },
      "changwat_id": "44"
    },
    "4409": {
      "name": {
        "th": "วาปีปทุม",
        "en": "Wapi Pathum"
      },
      "changwat_id": "44"
    },
    "4410": {
      "name": {
        "th": "นาดูน",
        "en": "Na Dun"
      },
      "changwat_id": "44"
    },
    "4411": {
      "name": {
        "th": "ยางสีสุราช",
        "en": "Yang Sisurat"
      },
      "changwat_id": "44"
    },
    "4412": {
      "name": {
        "th": "กุดรัง",
        "en": "Kut Rang"
      },
      "changwat_id": "44"
    },
    "4413": {
      "name": {
        "th": "ชื่นชม",
        "en": "Chuen Chom"
      },
      "changwat_id": "44"
    },
    "4501": {
      "name": {
        "th": "เมืองร้อยเอ็ด",
        "en": "Mueang Roi Et"
      },
      "changwat_id": "45"
    },
    "4502": {
      "name": {
        "th": "เกษตรวิสัย",
        "en": "Kaset Wisai"
      },
      "changwat_id": "45"
    },
    "4503": {
      "name": {
        "th": "ปทุมรัตต์",
        "en": "Pathum Rat"
      },
      "changwat_id": "45"
    },
    "4504": {
      "name": {
        "th": "จตุรพักตรพิมาน",
        "en": "Chaturaphak Phiman"
      },
      "changwat_id": "45"
    },
    "4505": {
      "name": {
        "th": "ธวัชบุรี",
        "en": "Thawat Buri"
      },
      "changwat_id": "45"
    },
    "4506": {
      "name": {
        "th": "พนมไพร",
        "en": "Phanom Phrai"
      },
      "changwat_id": "45"
    },
    "4507": {
      "name": {
        "th": "โพนทอง",
        "en": "Phon Thong"
      },
      "changwat_id": "45"
    },
    "4508": {
      "name": {
        "th": "โพธิ์ชัย",
        "en": "Pho Chai"
      },
      "changwat_id": "45"
    },
    "4509": {
      "name": {
        "th": "หนองพอก",
        "en": "Nong Phok"
      },
      "changwat_id": "45"
    },
    "4510": {
      "name": {
        "th": "เสลภูมิ",
        "en": "Selaphum"
      },
      "changwat_id": "45"
    },
    "4511": {
      "name": {
        "th": "สุวรรณภูมิ",
        "en": "Suwannaphum"
      },
      "changwat_id": "45"
    },
    "4512": {
      "name": {
        "th": "เมืองสรวง",
        "en": "Mueang Suang"
      },
      "changwat_id": "45"
    },
    "4513": {
      "name": {
        "th": "โพนทราย",
        "en": "Phon Sai"
      },
      "changwat_id": "45"
    },
    "4514": {
      "name": {
        "th": "อาจสามารถ",
        "en": "At Samat"
      },
      "changwat_id": "45"
    },
    "4515": {
      "name": {
        "th": "เมยวดี",
        "en": "Moei Wadi"
      },
      "changwat_id": "45"
    },
    "4516": {
      "name": {
        "th": "ศรีสมเด็จ",
        "en": "Si Somdet"
      },
      "changwat_id": "45"
    },
    "4517": {
      "name": {
        "th": "จังหาร",
        "en": "Changhan"
      },
      "changwat_id": "45"
    },
    "4518": {
      "name": {
        "th": "เชียงขวัญ",
        "en": "Chiang Khwan"
      },
      "changwat_id": "45"
    },
    "4519": {
      "name": {
        "th": "หนองฮี",
        "en": "Nong Hi"
      },
      "changwat_id": "45"
    },
    "4520": {
      "name": {
        "th": "ทุ่งเขาหลวง",
        "en": "Thung Khao Luang"
      },
      "changwat_id": "45"
    },
    "4601": {
      "name": {
        "th": "เมืองกาฬสินธุ์",
        "en": "Mueang Kalasin"
      },
      "changwat_id": "46"
    },
    "4602": {
      "name": {
        "th": "นามน",
        "en": "Na Mon"
      },
      "changwat_id": "46"
    },
    "4603": {
      "name": {
        "th": "กมลาไสย",
        "en": "Kamalasai"
      },
      "changwat_id": "46"
    },
    "4604": {
      "name": {
        "th": "ร่องคำ",
        "en": "Rong Kham"
      },
      "changwat_id": "46"
    },
    "4605": {
      "name": {
        "th": "กุฉินารายณ์",
        "en": "Kuchinarai"
      },
      "changwat_id": "46"
    },
    "4606": {
      "name": {
        "th": "เขาวง",
        "en": "Khao Wong"
      },
      "changwat_id": "46"
    },
    "4607": {
      "name": {
        "th": "ยางตลาด",
        "en": "Yang Talat"
      },
      "changwat_id": "46"
    },
    "4608": {
      "name": {
        "th": "ห้วยเม็ก",
        "en": "Huai Mek"
      },
      "changwat_id": "46"
    },
    "4609": {
      "name": {
        "th": "สหัสขันธ์",
        "en": "Sahatsakhan"
      },
      "changwat_id": "46"
    },
    "4610": {
      "name": {
        "th": "คำม่วง",
        "en": "Kham Muang"
      },
      "changwat_id": "46"
    },
    "4611": {
      "name": {
        "th": "ท่าคันโท",
        "en": "Tha Khantho"
      },
      "changwat_id": "46"
    },
    "4612": {
      "name": {
        "th": "หนองกุงศรี",
        "en": "Nong Kung Si"
      },
      "changwat_id": "46"
    },
    "4613": {
      "name": {
        "th": "สมเด็จ",
        "en": "Somdet"
      },
      "changwat_id": "46"
    },
    "4614": {
      "name": {
        "th": "ห้วยผึ้ง",
        "en": "Huai Phueng"
      },
      "changwat_id": "46"
    },
    "4615": {
      "name": {
        "th": "สามชัย",
        "en": "Sam Chai"
      },
      "changwat_id": "46"
    },
    "4616": {
      "name": {
        "th": "นาคู",
        "en": "Na Khu"
      },
      "changwat_id": "46"
    },
    "4617": {
      "name": {
        "th": "ดอนจาน",
        "en": "Don Chan"
      },
      "changwat_id": "46"
    },
    "4618": {
      "name": {
        "th": "ฆ้องชัย",
        "en": "Khong Chai"
      },
      "changwat_id": "46"
    },
    "4701": {
      "name": {
        "th": "เมืองสกลนคร",
        "en": "Mueang Sakon Nakhon"
      },
      "changwat_id": "47"
    },
    "4702": {
      "name": {
        "th": "กุสุมาลย์",
        "en": "Kusuman"
      },
      "changwat_id": "47"
    },
    "4703": {
      "name": {
        "th": "กุดบาก",
        "en": "Kut Bak"
      },
      "changwat_id": "47"
    },
    "4704": {
      "name": {
        "th": "พรรณานิคม",
        "en": "Phanna Nikhom"
      },
      "changwat_id": "47"
    },
    "4705": {
      "name": {
        "th": "พังโคน",
        "en": "Phanh khon"
      },
      "changwat_id": "47"
    },
    "4706": {
      "name": {
        "th": "วาริชภูมิ",
        "en": "Waritchaphum"
      },
      "changwat_id": "47"
    },
    "4707": {
      "name": {
        "th": "นิคมน้ำอูน",
        "en": "Nikhom Nam un"
      },
      "changwat_id": "47"
    },
    "4708": {
      "name": {
        "th": "วานรนิวาส",
        "en": "Wanon Niwat"
      },
      "changwat_id": "47"
    },
    "4709": {
      "name": {
        "th": "คำตากล้า",
        "en": "Kham Ta Kla"
      },
      "changwat_id": "47"
    },
    "4710": {
      "name": {
        "th": "บ้านม่วง",
        "en": "Ban Muang"
      },
      "changwat_id": "47"
    },
    "4711": {
      "name": {
        "th": "อากาศอำนวย",
        "en": "Akat Amnuai"
      },
      "changwat_id": "47"
    },
    "4712": {
      "name": {
        "th": "สว่างแดนดิน",
        "en": "Sawang Daen Din"
      },
      "changwat_id": "47"
    },
    "4713": {
      "name": {
        "th": "ส่องดาว",
        "en": "Song Dao"
      },
      "changwat_id": "47"
    },
    "4714": {
      "name": {
        "th": "เต่างอย",
        "en": "Tao Ngoi"
      },
      "changwat_id": "47"
    },
    "4715": {
      "name": {
        "th": "โคกศรีสุพรรณ",
        "en": "Khok Si Suphan"
      },
      "changwat_id": "47"
    },
    "4716": {
      "name": {
        "th": "เจริญศิลป์",
        "en": "Charoen Sin"
      },
      "changwat_id": "47"
    },
    "4717": {
      "name": {
        "th": "โพนนาแก้ว",
        "en": "Phon Na Kaeo"
      },
      "changwat_id": "47"
    },
    "4718": {
      "name": {
        "th": "ภูพาน",
        "en": "Phu Phan"
      },
      "changwat_id": "47"
    },
    "4801": {
      "name": {
        "th": "เมืองนครพนม",
        "en": "Mueang Nakhon Phanom"
      },
      "changwat_id": "48"
    },
    "4802": {
      "name": {
        "th": "ปลาปาก",
        "en": "Pla Pak"
      },
      "changwat_id": "48"
    },
    "4803": {
      "name": {
        "th": "ท่าอุเทน",
        "en": "Tha Uthen"
      },
      "changwat_id": "48"
    },
    "4804": {
      "name": {
        "th": "บ้านแพง",
        "en": "Ban Phaeng"
      },
      "changwat_id": "48"
    },
    "4805": {
      "name": {
        "th": "ธาตุพนม",
        "en": "That Phanom"
      },
      "changwat_id": "48"
    },
    "4806": {
      "name": {
        "th": "เรณูนคร",
        "en": "Renu Nakhon"
      },
      "changwat_id": "48"
    },
    "4807": {
      "name": {
        "th": "นาแก",
        "en": "Na Kae"
      },
      "changwat_id": "48"
    },
    "4808": {
      "name": {
        "th": "ศรีสงคราม",
        "en": "Si Songkhram"
      },
      "changwat_id": "48"
    },
    "4809": {
      "name": {
        "th": "นาหว้า",
        "en": "Na Wa"
      },
      "changwat_id": "48"
    },
    "4810": {
      "name": {
        "th": "โพนสวรรค์",
        "en": "Phon Sawan"
      },
      "changwat_id": "48"
    },
    "4811": {
      "name": {
        "th": "นาทม",
        "en": "Na Thom"
      },
      "changwat_id": "48"
    },
    "4812": {
      "name": {
        "th": "วังยาง",
        "en": "Wang Yang"
      },
      "changwat_id": "48"
    },
    "4901": {
      "name": {
        "th": "เมืองมุกดาหาร",
        "en": "Mueang Mukdahan"
      },
      "changwat_id": "49"
    },
    "4902": {
      "name": {
        "th": "นิคมคำสร้อย",
        "en": "Nikhom Kham Soi"
      },
      "changwat_id": "49"
    },
    "4903": {
      "name": {
        "th": "ดอนตาล",
        "en": "Don Tan"
      },
      "changwat_id": "49"
    },
    "4904": {
      "name": {
        "th": "ดงหลวง",
        "en": "Don Tan"
      },
      "changwat_id": "49"
    },
    "4905": {
      "name": {
        "th": "คำชะอี",
        "en": "Khamcha-i"
      },
      "changwat_id": "49"
    },
    "4906": {
      "name": {
        "th": "ว่านใหญ่",
        "en": "Wan Yai"
      },
      "changwat_id": "49"
    },
    "4907": {
      "name": {
        "th": "หนองสูง",
        "en": "Nong Sung"
      },
      "changwat_id": "49"
    },
    "5001": {
      "name": {
        "th": "เมืองเชียงใหม่",
        "en": "Mueang Chiang Mai"
      },
      "changwat_id": "50"
    },
    "5002": {
      "name": {
        "th": "จอมทอง",
        "en": "Chom Thong"
      },
      "changwat_id": "50"
    },
    "5003": {
      "name": {
        "th": "แม่แจ่ม",
        "en": "Mae Chaem"
      },
      "changwat_id": "50"
    },
    "5004": {
      "name": {
        "th": "เชียงดาว",
        "en": "Chiang Dao"
      },
      "changwat_id": "50"
    },
    "5005": {
      "name": {
        "th": "ดอยสะเก็ด",
        "en": "Doi Saket"
      },
      "changwat_id": "50"
    },
    "5006": {
      "name": {
        "th": "แม่แตง",
        "en": "Mae Taeng"
      },
      "changwat_id": "50"
    },
    "5007": {
      "name": {
        "th": "แม่ริม",
        "en": "Mae Rim"
      },
      "changwat_id": "50"
    },
    "5008": {
      "name": {
        "th": "สะเมิง",
        "en": "Samoeng"
      },
      "changwat_id": "50"
    },
    "5009": {
      "name": {
        "th": "ฝาง",
        "en": "Fang"
      },
      "changwat_id": "50"
    },
    "5010": {
      "name": {
        "th": "แม่อาย",
        "en": "Mae Ai"
      },
      "changwat_id": "50"
    },
    "5011": {
      "name": {
        "th": "พร้าว",
        "en": "Phrao"
      },
      "changwat_id": "50"
    },
    "5012": {
      "name": {
        "th": "สันป่าตอง",
        "en": "San Pa Tong"
      },
      "changwat_id": "50"
    },
    "5013": {
      "name": {
        "th": "สันกำแพง",
        "en": "San Kamphaeng"
      },
      "changwat_id": "50"
    },
    "5014": {
      "name": {
        "th": "สันทราย",
        "en": "San Sai"
      },
      "changwat_id": "50"
    },
    "5015": {
      "name": {
        "th": "หางดง",
        "en": "Hang Dong"
      },
      "changwat_id": "50"
    },
    "5016": {
      "name": {
        "th": "ฮอด",
        "en": "Hot"
      },
      "changwat_id": "50"
    },
    "5017": {
      "name": {
        "th": "ดอยเต่า",
        "en": "Doi Tao"
      },
      "changwat_id": "50"
    },
    "5018": {
      "name": {
        "th": "อมก๋อย",
        "en": "Omkoi"
      },
      "changwat_id": "50"
    },
    "5019": {
      "name": {
        "th": "สารภี",
        "en": "Saraphi"
      },
      "changwat_id": "50"
    },
    "5020": {
      "name": {
        "th": "เวียงแหง",
        "en": "Wiang Haeng"
      },
      "changwat_id": "50"
    },
    "5021": {
      "name": {
        "th": "ไชยปราการ",
        "en": "Chai Prakan"
      },
      "changwat_id": "50"
    },
    "5022": {
      "name": {
        "th": "แม่วาง",
        "en": "Mae Wang"
      },
      "changwat_id": "50"
    },
    "5023": {
      "name": {
        "th": "แม่ออน",
        "en": "Mae On"
      },
      "changwat_id": "50"
    },
    "5024": {
      "name": {
        "th": "ดอยหล่อ",
        "en": "Doi Lo"
      },
      "changwat_id": "50"
    },
    "5025": {
      "name": {
        "th": "กัลยาณิวัฒนา",
        "en": "Kanlayaniwatthana"
      },
      "changwat_id": "50"
    },
    "5101": {
      "name": {
        "th": "เมืองลำพูน",
        "en": "Mueang Lamphun"
      },
      "changwat_id": "51"
    },
    "5102": {
      "name": {
        "th": "แม่ทา",
        "en": "Mae Tha"
      },
      "changwat_id": "51"
    },
    "5103": {
      "name": {
        "th": "บ้านโฮ่ง",
        "en": "Ban Hong"
      },
      "changwat_id": "51"
    },
    "5104": {
      "name": {
        "th": "ลี้",
        "en": "Li"
      },
      "changwat_id": "51"
    },
    "5105": {
      "name": {
        "th": "ทุ่งหัวช้าง",
        "en": "Thung Hua Chang"
      },
      "changwat_id": "51"
    },
    "5106": {
      "name": {
        "th": "ป่าซาง",
        "en": "Pa Sang"
      },
      "changwat_id": "51"
    },
    "5107": {
      "name": {
        "th": "บ้านธิ",
        "en": "Ban Thi"
      },
      "changwat_id": "51"
    },
    "5108": {
      "name": {
        "th": "เวียงหนองล่อง",
        "en": "Wiang Nong Long"
      },
      "changwat_id": "51"
    },
    "5201": {
      "name": {
        "th": "เมืองลำปาง",
        "en": "Mueang Lampang"
      },
      "changwat_id": "52"
    },
    "5202": {
      "name": {
        "th": "แม่เมาะ",
        "en": "Mae Mo"
      },
      "changwat_id": "52"
    },
    "5203": {
      "name": {
        "th": "เกาะคา",
        "en": "Ko kha"
      },
      "changwat_id": "52"
    },
    "5204": {
      "name": {
        "th": "เสริมงาม",
        "en": "Soem Ngam"
      },
      "changwat_id": "52"
    },
    "5205": {
      "name": {
        "th": "งาว",
        "en": "Ngao"
      },
      "changwat_id": "52"
    },
    "5206": {
      "name": {
        "th": "แจ้ห่ม",
        "en": "Chae Hom"
      },
      "changwat_id": "52"
    },
    "5207": {
      "name": {
        "th": "วังเหนือ",
        "en": "Wang Nuea"
      },
      "changwat_id": "52"
    },
    "5208": {
      "name": {
        "th": "เถิน",
        "en": "Thoen"
      },
      "changwat_id": "52"
    },
    "5209": {
      "name": {
        "th": "แม่พริก",
        "en": "Mae Phrik"
      },
      "changwat_id": "52"
    },
    "5210": {
      "name": {
        "th": "แม่ทะ",
        "en": "Mae Tha"
      },
      "changwat_id": "52"
    },
    "5211": {
      "name": {
        "th": "สบปราบ",
        "en": "Sop Prap"
      },
      "changwat_id": "52"
    },
    "5212": {
      "name": {
        "th": "ห้างฉัตร",
        "en": "Hang Chat"
      },
      "changwat_id": "52"
    },
    "5213": {
      "name": {
        "th": "เมืองปาน",
        "en": "Mueang Pan"
      },
      "changwat_id": "52"
    },
    "5301": {
      "name": {
        "th": "เมืองอุตรดิตถ์",
        "en": "Mueang Uttaradit"
      },
      "changwat_id": "53"
    },
    "5302": {
      "name": {
        "th": "ตรอน",
        "en": "Tron"
      },
      "changwat_id": "53"
    },
    "5303": {
      "name": {
        "th": "ท่าปลา",
        "en": "Tha Pla"
      },
      "changwat_id": "53"
    },
    "5304": {
      "name": {
        "th": "น้ำปาด",
        "en": "Nam Pat"
      },
      "changwat_id": "53"
    },
    "5305": {
      "name": {
        "th": "ฟากท่า",
        "en": "Fak Tha"
      },
      "changwat_id": "53"
    },
    "5306": {
      "name": {
        "th": "บ้านโคก",
        "en": "Ban Khok"
      },
      "changwat_id": "53"
    },
    "5307": {
      "name": {
        "th": "พิชัย",
        "en": "Phichai"
      },
      "changwat_id": "53"
    },
    "5308": {
      "name": {
        "th": "ลับแล",
        "en": "Laplae"
      },
      "changwat_id": "53"
    },
    "5309": {
      "name": {
        "th": "ทองแสนขัน",
        "en": "Thong Saen Khan"
      },
      "changwat_id": "53"
    },
    "5401": {
      "name": {
        "th": "เมืองแพร่",
        "en": "Mueang Phrae"
      },
      "changwat_id": "54"
    },
    "5402": {
      "name": {
        "th": "ร้องกวาง",
        "en": "Mueang Phrae"
      },
      "changwat_id": "54"
    },
    "5403": {
      "name": {
        "th": "ลอง",
        "en": "Long"
      },
      "changwat_id": "54"
    },
    "5404": {
      "name": {
        "th": "สูงเม่น",
        "en": "Sung Men"
      },
      "changwat_id": "54"
    },
    "5405": {
      "name": {
        "th": "เด่นชัย",
        "en": "Den Chai"
      },
      "changwat_id": "54"
    },
    "5406": {
      "name": {
        "th": "สอง",
        "en": "Song"
      },
      "changwat_id": "54"
    },
    "5407": {
      "name": {
        "th": "วังชิ้น",
        "en": "Wang Chin"
      },
      "changwat_id": "54"
    },
    "5408": {
      "name": {
        "th": "หนองม่วงไข่",
        "en": "Nong Muang Khai"
      },
      "changwat_id": "54"
    },
    "5501": {
      "name": {
        "th": "เมืองน่าน",
        "en": "Mueang Nan"
      },
      "changwat_id": "55"
    },
    "5502": {
      "name": {
        "th": "แม่จริม",
        "en": "Mae Charim"
      },
      "changwat_id": "55"
    },
    "5503": {
      "name": {
        "th": "บ้านหลวง",
        "en": "Ban Luang"
      },
      "changwat_id": "55"
    },
    "5504": {
      "name": {
        "th": "นาน้อย",
        "en": "Na Noi"
      },
      "changwat_id": "55"
    },
    "5505": {
      "name": {
        "th": "ปัว",
        "en": "Pua"
      },
      "changwat_id": "55"
    },
    "5506": {
      "name": {
        "th": "ท่าวังผา",
        "en": "Tha Wang Pha"
      },
      "changwat_id": "55"
    },
    "5507": {
      "name": {
        "th": "เวียงสา",
        "en": "Wiang Sa"
      },
      "changwat_id": "55"
    },
    "5508": {
      "name": {
        "th": "ทุ่งช้าง",
        "en": "Thung Chang"
      },
      "changwat_id": "55"
    },
    "5509": {
      "name": {
        "th": "เชียงกลาง",
        "en": "Chiang Klang"
      },
      "changwat_id": "55"
    },
    "5510": {
      "name": {
        "th": "นาหมื่น",
        "en": "Na Muen"
      },
      "changwat_id": "55"
    },
    "5511": {
      "name": {
        "th": "สันติสุข",
        "en": "Santi Suk"
      },
      "changwat_id": "55"
    },
    "5512": {
      "name": {
        "th": "บ่อเกลือ",
        "en": "Bo Kluea"
      },
      "changwat_id": "55"
    },
    "5513": {
      "name": {
        "th": "สองแคว",
        "en": "Song Khwae"
      },
      "changwat_id": "55"
    },
    "5514": {
      "name": {
        "th": "ภูเพียง",
        "en": "Phu Phiang"
      },
      "changwat_id": "55"
    },
    "5515": {
      "name": {
        "th": "เฉลิมพระเกียรติ",
        "en": "Chaloem Phra Kiet"
      },
      "changwat_id": "55"
    },
    "5601": {
      "name": {
        "th": "เมืองพะเยา",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5602": {
      "name": {
        "th": "จุน",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5603": {
      "name": {
        "th": "เชียงคำ",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5604": {
      "name": {
        "th": "เชียงม่วน",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5605": {
      "name": {
        "th": "ดอกคำใต้",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5606": {
      "name": {
        "th": "ปง",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5607": {
      "name": {
        "th": "แม่ใจ",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5608": {
      "name": {
        "th": "ภูซาง",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5609": {
      "name": {
        "th": "ภูกามยาว",
        "en": "Mueang Phayao"
      },
      "changwat_id": "56"
    },
    "5701": {
      "name": {
        "th": "เมืองเชียงราย",
        "en": "Mueang Chiang Rai"
      },
      "changwat_id": "57"
    },
    "5702": {
      "name": {
        "th": "เวียงชัย",
        "en": "Wiang Chai"
      },
      "changwat_id": "57"
    },
    "5703": {
      "name": {
        "th": "เชียงของ",
        "en": "Chiang Khong"
      },
      "changwat_id": "57"
    },
    "5704": {
      "name": {
        "th": "เทิง",
        "en": "Thoeng"
      },
      "changwat_id": "57"
    },
    "5705": {
      "name": {
        "th": "พาน",
        "en": "Phan"
      },
      "changwat_id": "57"
    },
    "5706": {
      "name": {
        "th": "ป่าแดด",
        "en": "Pa Daet"
      },
      "changwat_id": "57"
    },
    "5707": {
      "name": {
        "th": "แม่จัน",
        "en": "Mae Chan"
      },
      "changwat_id": "57"
    },
    "5708": {
      "name": {
        "th": "เชียงแสน",
        "en": "Chiang Saen"
      },
      "changwat_id": "57"
    },
    "5709": {
      "name": {
        "th": "แม่สาย",
        "en": "Mae Sai"
      },
      "changwat_id": "57"
    },
    "5710": {
      "name": {
        "th": "แม่สรวย",
        "en": "Mae Suai"
      },
      "changwat_id": "57"
    },
    "5711": {
      "name": {
        "th": "เวียงป่าเป้า",
        "en": "Wiang Pa Pao"
      },
      "changwat_id": "57"
    },
    "5712": {
      "name": {
        "th": "พญาเม็งราย",
        "en": "Phaya Mengrai"
      },
      "changwat_id": "57"
    },
    "5713": {
      "name": {
        "th": "เวียงแก่น",
        "en": "Wiang Kaen"
      },
      "changwat_id": "57"
    },
    "5714": {
      "name": {
        "th": "ขุนตาล",
        "en": "Khun Tan"
      },
      "changwat_id": "57"
    },
    "5715": {
      "name": {
        "th": "แม่ฟ้าหลวง",
        "en": "Mae Fa Luang"
      },
      "changwat_id": "57"
    },
    "5716": {
      "name": {
        "th": "แม่ลาว",
        "en": "Mae Lao"
      },
      "changwat_id": "57"
    },
    "5717": {
      "name": {
        "th": "เวียงเชียงรุ้ง",
        "en": "Wiang Chiang Rung"
      },
      "changwat_id": "57"
    },
    "5718": {
      "name": {
        "th": "ดอยหลวง",
        "en": "Doi Luang"
      },
      "changwat_id": "57"
    },
    "5801": {
      "name": {
        "th": "เมืองแม่ฮ่องสอน",
        "en": "Mueang Mae Hong Son"
      },
      "changwat_id": "58"
    },
    "5802": {
      "name": {
        "th": "ขุนยวม",
        "en": "Khun Yuam"
      },
      "changwat_id": "58"
    },
    "5803": {
      "name": {
        "th": "ปาย",
        "en": "Pai"
      },
      "changwat_id": "58"
    },
    "5804": {
      "name": {
        "th": "แม่สะเรียง",
        "en": "Mae Sariang"
      },
      "changwat_id": "58"
    },
    "5805": {
      "name": {
        "th": "แม่ลาน้อย",
        "en": "Mae La Noi"
      },
      "changwat_id": "58"
    },
    "5806": {
      "name": {
        "th": "สบเมย",
        "en": "Sop Moei"
      },
      "changwat_id": "58"
    },
    "5807": {
      "name": {
        "th": "ปางมะผ้า",
        "en": "Pang Mapha"
      },
      "changwat_id": "58"
    },
    "6001": {
      "name": {
        "th": "เมืองนครสวรรค์",
        "en": "Mueang Nakhon Sawan"
      },
      "changwat_id": "60"
    },
    "6002": {
      "name": {
        "th": "โกรกพระ",
        "en": "Krok Phra"
      },
      "changwat_id": "60"
    },
    "6003": {
      "name": {
        "th": "ชุมแสง",
        "en": "Chum Saeng"
      },
      "changwat_id": "60"
    },
    "6004": {
      "name": {
        "th": "หนองบัว",
        "en": "Nong Bua"
      },
      "changwat_id": "60"
    },
    "6005": {
      "name": {
        "th": "บรรพตพิสัย",
        "en": "Banphot Phisai"
      },
      "changwat_id": "60"
    },
    "6006": {
      "name": {
        "th": "เก้าเลี้ยว",
        "en": "Kao Liao"
      },
      "changwat_id": "60"
    },
    "6007": {
      "name": {
        "th": "ตาคลี",
        "en": "Takhli"
      },
      "changwat_id": "60"
    },
    "6008": {
      "name": {
        "th": "ท่าตะโก",
        "en": "Tha Tako"
      },
      "changwat_id": "60"
    },
    "6009": {
      "name": {
        "th": "ไพศาลี",
        "en": "Phaisali"
      },
      "changwat_id": "60"
    },
    "6010": {
      "name": {
        "th": "พยุหะคีรี",
        "en": "Phayuha Hkiri"
      },
      "changwat_id": "60"
    },
    "6011": {
      "name": {
        "th": "ลาดยาว",
        "en": "Lat Yao"
      },
      "changwat_id": "60"
    },
    "6012": {
      "name": {
        "th": "ตากฟ้า",
        "en": "Tak Fa"
      },
      "changwat_id": "60"
    },
    "6013": {
      "name": {
        "th": "แม่วงก์",
        "en": "Mae Wong"
      },
      "changwat_id": "60"
    },
    "6014": {
      "name": {
        "th": "แม่เปิน",
        "en": "Mae Pern"
      },
      "changwat_id": "60"
    },
    "6015": {
      "name": {
        "th": "ชุมตาบง",
        "en": "Chum Ta Bong"
      },
      "changwat_id": "60"
    },
    "6101": {
      "name": {
        "th": "เมืองอุทัยธานี",
        "en": "Mueang Uthai Thani"
      },
      "changwat_id": "61"
    },
    "6102": {
      "name": {
        "th": "ทัพทัน",
        "en": "Thap Than"
      },
      "changwat_id": "61"
    },
    "6103": {
      "name": {
        "th": "สว่างอารมณ์",
        "en": "Sawang Arom"
      },
      "changwat_id": "61"
    },
    "6104": {
      "name": {
        "th": "หนองฉาง",
        "en": "Nong Chang"
      },
      "changwat_id": "61"
    },
    "6105": {
      "name": {
        "th": "หนองขาหย่าง",
        "en": "Nong Khayang"
      },
      "changwat_id": "61"
    },
    "6106": {
      "name": {
        "th": "บ้านไร่",
        "en": "Ban Rai"
      },
      "changwat_id": "61"
    },
    "6107": {
      "name": {
        "th": "ลานสัก",
        "en": "Lan Sak"
      },
      "changwat_id": "61"
    },
    "6108": {
      "name": {
        "th": "ห้วยคต",
        "en": "Huai Khot"
      },
      "changwat_id": "61"
    },
    "6201": {
      "name": {
        "th": "เมืองกำแพงเพชร",
        "en": "Mueang Kamphaeng Phet"
      },
      "changwat_id": "62"
    },
    "6202": {
      "name": {
        "th": "ไทรงาม",
        "en": "Sai Ngam"
      },
      "changwat_id": "62"
    },
    "6203": {
      "name": {
        "th": "คลองลาน",
        "en": "Khlong Lan"
      },
      "changwat_id": "62"
    },
    "6204": {
      "name": {
        "th": "ขาณุวรลักษบุรี",
        "en": "Khanu Woralaksaburi"
      },
      "changwat_id": "62"
    },
    "6205": {
      "name": {
        "th": "คลองขลุง",
        "en": "Khlong Khlung"
      },
      "changwat_id": "62"
    },
    "6206": {
      "name": {
        "th": "พรานกระต่าย",
        "en": "Phran Kratai"
      },
      "changwat_id": "62"
    },
    "6207": {
      "name": {
        "th": "ลานกระบือ",
        "en": "Lan Krabue"
      },
      "changwat_id": "62"
    },
    "6208": {
      "name": {
        "th": "ทรายทองวัฒนา",
        "en": "Sai Thong Watthana"
      },
      "changwat_id": "62"
    },
    "6209": {
      "name": {
        "th": "ปางศิลาทอง",
        "en": "Pang Sila Thong"
      },
      "changwat_id": "62"
    },
    "6210": {
      "name": {
        "th": "บึงสามัคคี",
        "en": "Bueng Samakkhi"
      },
      "changwat_id": "62"
    },
    "6211": {
      "name": {
        "th": "โกสัมพีนคร",
        "en": "Kosamphi Nakhon"
      },
      "changwat_id": "62"
    },
    "6301": {
      "name": {
        "th": "เมืองตาก",
        "en": "Mueang Tak"
      },
      "changwat_id": "63"
    },
    "6302": {
      "name": {
        "th": "บ้านตาก",
        "en": "Ban Tak"
      },
      "changwat_id": "63"
    },
    "6303": {
      "name": {
        "th": "สามเงา",
        "en": "Sam Ngao"
      },
      "changwat_id": "63"
    },
    "6304": {
      "name": {
        "th": "แม่ระมาด",
        "en": "Mae Ramat"
      },
      "changwat_id": "63"
    },
    "6305": {
      "name": {
        "th": "ท่าสองยาง",
        "en": "Tha Song Yang"
      },
      "changwat_id": "63"
    },
    "6306": {
      "name": {
        "th": "แม่สอด",
        "en": "Mae Sot"
      },
      "changwat_id": "63"
    },
    "6307": {
      "name": {
        "th": "พบพระ",
        "en": "Phop Phra"
      },
      "changwat_id": "63"
    },
    "6308": {
      "name": {
        "th": "อุ้มผาง",
        "en": "Um Phang"
      },
      "changwat_id": "63"
    },
    "6309": {
      "name": {
        "th": "วังเจ้า",
        "en": "Wang Chao"
      },
      "changwat_id": "63"
    },
    "6401": {
      "name": {
        "th": "เมืองสุโขทัย",
        "en": "Mueang Sukhothai"
      },
      "changwat_id": "64"
    },
    "6402": {
      "name": {
        "th": "บ้านด่านลานหอย",
        "en": "Ban Dan Lan Hoi"
      },
      "changwat_id": "64"
    },
    "6403": {
      "name": {
        "th": "คีรีมาศ",
        "en": "Khiri Mat"
      },
      "changwat_id": "64"
    },
    "6404": {
      "name": {
        "th": "กงไกรลาศ",
        "en": "Kong Krailat"
      },
      "changwat_id": "64"
    },
    "6405": {
      "name": {
        "th": "ศรีสัชนาลัย",
        "en": "Si Satchanalai"
      },
      "changwat_id": "64"
    },
    "6406": {
      "name": {
        "th": "ศรีสำโรง",
        "en": "Si Samrong"
      },
      "changwat_id": "64"
    },
    "6407": {
      "name": {
        "th": "สวรรคโลก",
        "en": "Sawankhalok"
      },
      "changwat_id": "64"
    },
    "6408": {
      "name": {
        "th": "ศรีนคร",
        "en": "Si Nakhon"
      },
      "changwat_id": "64"
    },
    "6409": {
      "name": {
        "th": "ทุ่งเสลี่ยม",
        "en": "Thung Saliam"
      },
      "changwat_id": "64"
    },
    "6501": {
      "name": {
        "th": "เมืองพิษณุโลก",
        "en": "Mueang Phitsanulok"
      },
      "changwat_id": "65"
    },
    "6502": {
      "name": {
        "th": "นครไทย",
        "en": "Nakhon Thai"
      },
      "changwat_id": "65"
    },
    "6503": {
      "name": {
        "th": "ชาติตระการ",
        "en": "Chat Trakan"
      },
      "changwat_id": "65"
    },
    "6504": {
      "name": {
        "th": "บางระกำ",
        "en": "Bang Rakam"
      },
      "changwat_id": "65"
    },
    "6505": {
      "name": {
        "th": "บางกระทุ่ม",
        "en": "Bang Krathum"
      },
      "changwat_id": "65"
    },
    "6506": {
      "name": {
        "th": "พรหมพิราม",
        "en": "Phrom Phiram"
      },
      "changwat_id": "65"
    },
    "6507": {
      "name": {
        "th": "วัดโบสถ์",
        "en": "Wat Bot"
      },
      "changwat_id": "65"
    },
    "6508": {
      "name": {
        "th": "วังทอง",
        "en": "Wang Thong"
      },
      "changwat_id": "65"
    },
    "6509": {
      "name": {
        "th": "เนินมะปราง",
        "en": "Noen Maprang"
      },
      "changwat_id": "65"
    },
    "6601": {
      "name": {
        "th": "เมืองพิจิตร",
        "en": "Mueang Phichit"
      },
      "changwat_id": "66"
    },
    "6602": {
      "name": {
        "th": "วังทรายพูน",
        "en": "Wang Sai Phun"
      },
      "changwat_id": "66"
    },
    "6603": {
      "name": {
        "th": "โพธิ์ประทับช้าง",
        "en": "Pho Prathap Chang"
      },
      "changwat_id": "66"
    },
    "6604": {
      "name": {
        "th": "ตะพานหิน",
        "en": "Taphan Hin"
      },
      "changwat_id": "66"
    },
    "6605": {
      "name": {
        "th": "บางมูลนาก",
        "en": "Bang Mun Nak"
      },
      "changwat_id": "66"
    },
    "6606": {
      "name": {
        "th": "โพทะเล",
        "en": "Pho Thale"
      },
      "changwat_id": "66"
    },
    "6607": {
      "name": {
        "th": "สามง่าม",
        "en": "Sam Ngam"
      },
      "changwat_id": "66"
    },
    "6608": {
      "name": {
        "th": "ทับคล้อ",
        "en": "Tap Khlo"
      },
      "changwat_id": "66"
    },
    "6609": {
      "name": {
        "th": "สากเหล็ก",
        "en": "Sak Lek"
      },
      "changwat_id": "66"
    },
    "6610": {
      "name": {
        "th": "บึงนาราง",
        "en": "Bueng Na Rang"
      },
      "changwat_id": "66"
    },
    "6611": {
      "name": {
        "th": "ดงเจริญ",
        "en": "Dong Charoen"
      },
      "changwat_id": "66"
    },
    "6612": {
      "name": {
        "th": "วชิรบารมี",
        "en": "Wachirabarami"
      },
      "changwat_id": "66"
    },
    "6701": {
      "name": {
        "th": "เมืองเพชรบูรณ์",
        "en": "Mueang Phetchabun"
      },
      "changwat_id": "67"
    },
    "6702": {
      "name": {
        "th": "ชนแดน",
        "en": "Chon Daen"
      },
      "changwat_id": "67"
    },
    "6703": {
      "name": {
        "th": "หล่มสัก",
        "en": "Lom Sak"
      },
      "changwat_id": "67"
    },
    "6704": {
      "name": {
        "th": "หล่มเก่า",
        "en": "Lom Kao"
      },
      "changwat_id": "67"
    },
    "6705": {
      "name": {
        "th": "วิเชียรบุรี",
        "en": "Wichian Buri"
      },
      "changwat_id": "67"
    },
    "6706": {
      "name": {
        "th": "ศรีเทพ",
        "en": "Si Thep"
      },
      "changwat_id": "67"
    },
    "6707": {
      "name": {
        "th": "หนองไผ่",
        "en": "Nong Phai"
      },
      "changwat_id": "67"
    },
    "6708": {
      "name": {
        "th": "บึงสามพัน",
        "en": "Bueng Sam Phan"
      },
      "changwat_id": "67"
    },
    "6709": {
      "name": {
        "th": "น้ำหนาว",
        "en": "Nam Nao"
      },
      "changwat_id": "67"
    },
    "6710": {
      "name": {
        "th": "วังโป่ง",
        "en": "Wang Pong"
      },
      "changwat_id": "67"
    },
    "6711": {
      "name": {
        "th": "เขาค้อ",
        "en": "Khao kho"
      },
      "changwat_id": "67"
    },
    "7001": {
      "name": {
        "th": "เมืองราชบุรี",
        "en": "Mueang Ratchaburi"
      },
      "changwat_id": "70"
    },
    "7002": {
      "name": {
        "th": "จอมบึง",
        "en": "Chom Bueng"
      },
      "changwat_id": "70"
    },
    "7003": {
      "name": {
        "th": "สวนผึ้ง",
        "en": "Suan Phueng"
      },
      "changwat_id": "70"
    },
    "7004": {
      "name": {
        "th": "ดำเนินสะดวก",
        "en": "Damnoen Saduak"
      },
      "changwat_id": "70"
    },
    "7005": {
      "name": {
        "th": "บ้านโป่ง",
        "en": "Ban Pong"
      },
      "changwat_id": "70"
    },
    "7006": {
      "name": {
        "th": "บางแพ",
        "en": "Bang Phae"
      },
      "changwat_id": "70"
    },
    "7007": {
      "name": {
        "th": "โพธาราม",
        "en": "Photharam"
      },
      "changwat_id": "70"
    },
    "7008": {
      "name": {
        "th": "ปากท่อ",
        "en": "Pak Tho"
      },
      "changwat_id": "70"
    },
    "7009": {
      "name": {
        "th": "วัดเพลง",
        "en": "Wat Phleng"
      },
      "changwat_id": "70"
    },
    "7010": {
      "name": {
        "th": "บ้านคา",
        "en": "Ban Kha"
      },
      "changwat_id": "70"
    },
    "7101": {
      "name": {
        "th": "เมืองกาญจนบุรี",
        "en": "Mueang Kanchanaburi"
      },
      "changwat_id": "71"
    },
    "7102": {
      "name": {
        "th": "ไทรโยค",
        "en": "Sai Yok"
      },
      "changwat_id": "71"
    },
    "7103": {
      "name": {
        "th": "บ่อพลอย",
        "en": "Bo Phloi"
      },
      "changwat_id": "71"
    },
    "7104": {
      "name": {
        "th": "ศรีสวัสดิ์",
        "en": "Si Sawat"
      },
      "changwat_id": "71"
    },
    "7105": {
      "name": {
        "th": "ท่ามะกา",
        "en": "Tha Maka"
      },
      "changwat_id": "71"
    },
    "7106": {
      "name": {
        "th": "ท่าม่วง",
        "en": "Tha Muang"
      },
      "changwat_id": "71"
    },
    "7107": {
      "name": {
        "th": "ทองผาภูมิ",
        "en": "Thong Pha Phum"
      },
      "changwat_id": "71"
    },
    "7108": {
      "name": {
        "th": "สังขละบุรี",
        "en": "Sangkhla Buri"
      },
      "changwat_id": "71"
    },
    "7109": {
      "name": {
        "th": "พนมทวน",
        "en": "Phanom Thuan"
      },
      "changwat_id": "71"
    },
    "7110": {
      "name": {
        "th": "เลาขวัญ",
        "en": "Lao Khwan"
      },
      "changwat_id": "71"
    },
    "7111": {
      "name": {
        "th": "ด่านมะขามเตี้ย",
        "en": "Dan Makham Tia"
      },
      "changwat_id": "71"
    },
    "7112": {
      "name": {
        "th": "หนองปรือ",
        "en": "Nong Prue"
      },
      "changwat_id": "71"
    },
    "7113": {
      "name": {
        "th": "ห้วยกระเจา",
        "en": "Huai Krachao"
      },
      "changwat_id": "71"
    },
    "7201": {
      "name": {
        "th": "เมืองสุพรรณบุรี",
        "en": "Mueang Saphan Buri"
      },
      "changwat_id": "72"
    },
    "7202": {
      "name": {
        "th": "เดิมบางนางบวช",
        "en": "Doem Bang Nang Buat"
      },
      "changwat_id": "72"
    },
    "7203": {
      "name": {
        "th": "ด่านช้าง",
        "en": "Dan Chang"
      },
      "changwat_id": "72"
    },
    "7204": {
      "name": {
        "th": "บางปลาม้า",
        "en": "Bang Pla Ma"
      },
      "changwat_id": "72"
    },
    "7205": {
      "name": {
        "th": "ศรีประจันต์",
        "en": "Si Prachan"
      },
      "changwat_id": "72"
    },
    "7206": {
      "name": {
        "th": "ดอนเจดีย์",
        "en": "Don Chedi"
      },
      "changwat_id": "72"
    },
    "7207": {
      "name": {
        "th": "สองพี่น้อง",
        "en": "Song Phi Nong"
      },
      "changwat_id": "72"
    },
    "7208": {
      "name": {
        "th": "สามชุก",
        "en": "Sam Chuk"
      },
      "changwat_id": "72"
    },
    "7209": {
      "name": {
        "th": "อู่ทอง",
        "en": "U Thong"
      },
      "changwat_id": "72"
    },
    "7210": {
      "name": {
        "th": "หนองหญ้าไซ",
        "en": "Nong Ya Sai"
      },
      "changwat_id": "72"
    },
    "7301": {
      "name": {
        "th": "เมืองนครปฐม",
        "en": "mueang Nakhon Pathom"
      },
      "changwat_id": "73"
    },
    "7302": {
      "name": {
        "th": "กำแพงแสน",
        "en": "Kamphaeng Saen"
      },
      "changwat_id": "73"
    },
    "7303": {
      "name": {
        "th": "นครชัยศรี",
        "en": "Nakhon Chai Si"
      },
      "changwat_id": "73"
    },
    "7304": {
      "name": {
        "th": "ดอนตูม",
        "en": "Don Tum"
      },
      "changwat_id": "73"
    },
    "7305": {
      "name": {
        "th": "บางเลน",
        "en": "Bang Len"
      },
      "changwat_id": "73"
    },
    "7306": {
      "name": {
        "th": "สามพราน",
        "en": "Sam Phran"
      },
      "changwat_id": "73"
    },
    "7307": {
      "name": {
        "th": "พุทธมณฑล",
        "en": "Phutthamonthon"
      },
      "changwat_id": "73"
    },
    "7401": {
      "name": {
        "th": "เมืองสมุทรสาคร",
        "en": "Mueang Samut Sakhon"
      },
      "changwat_id": "74"
    },
    "7402": {
      "name": {
        "th": "กระทุ่มแบน",
        "en": "Krathum Baen"
      },
      "changwat_id": "74"
    },
    "7403": {
      "name": {
        "th": "บ้านแพ้ว",
        "en": "Ban Phaeo"
      },
      "changwat_id": "74"
    },
    "7501": {
      "name": {
        "th": "เมืองสมุทรสงคราม",
        "en": "Mueang Samut Songkhram"
      },
      "changwat_id": "75"
    },
    "7502": {
      "name": {
        "th": "บางคนที",
        "en": "Bang Khonthi"
      },
      "changwat_id": "75"
    },
    "7503": {
      "name": {
        "th": "อัมพวา",
        "en": "Amphawa"
      },
      "changwat_id": "75"
    },
    "7601": {
      "name": {
        "th": "เมืองเพชรบุรี",
        "en": "Mueang Phetchaburi"
      },
      "changwat_id": "76"
    },
    "7602": {
      "name": {
        "th": "เขาย้อย",
        "en": "Khao Yoi"
      },
      "changwat_id": "76"
    },
    "7603": {
      "name": {
        "th": "หนองหญ้าปล้อง",
        "en": "Nong Ya Plong"
      },
      "changwat_id": "76"
    },
    "7604": {
      "name": {
        "th": "ชะอำ",
        "en": "Cha-am"
      },
      "changwat_id": "76"
    },
    "7605": {
      "name": {
        "th": "ท่ายาง",
        "en": "Tha Yang"
      },
      "changwat_id": "76"
    },
    "7606": {
      "name": {
        "th": "บ้านลาด",
        "en": "Ban Lat"
      },
      "changwat_id": "76"
    },
    "7607": {
      "name": {
        "th": "บ้านแหลม",
        "en": "Ban Laem"
      },
      "changwat_id": "76"
    },
    "7608": {
      "name": {
        "th": "แก่งกระจาน",
        "en": "Kaeng Krachan"
      },
      "changwat_id": "76"
    },
    "7701": {
      "name": {
        "th": "เมืองประจวบคีรีขันธ์",
        "en": "Mueang Prachuap Khiri Khan"
      },
      "changwat_id": "77"
    },
    "7702": {
      "name": {
        "th": "กุยบุรี",
        "en": "Kui Buri"
      },
      "changwat_id": "77"
    },
    "7703": {
      "name": {
        "th": "ทับสะแก",
        "en": "Thap Sakae"
      },
      "changwat_id": "77"
    },
    "7704": {
      "name": {
        "th": "บางสะพาน",
        "en": "Bang Saphan"
      },
      "changwat_id": "77"
    },
    "7705": {
      "name": {
        "th": "บางสะพานน้อย",
        "en": "Bang Saphan Noi"
      },
      "changwat_id": "77"
    },
    "7706": {
      "name": {
        "th": "ปราณบุรี",
        "en": "Pran Buri"
      },
      "changwat_id": "77"
    },
    "7707": {
      "name": {
        "th": "หัวหิน",
        "en": "Hua Hin"
      },
      "changwat_id": "77"
    },
    "7708": {
      "name": {
        "th": "สามร้อยยอด",
        "en": "Sam Roi Yot"
      },
      "changwat_id": "77"
    },
    "8001": {
      "name": {
        "th": "เมืองนครศรีธรรมราช",
        "en": "Mueang Nakhon Si Thammarat"
      },
      "changwat_id": "80"
    },
    "8002": {
      "name": {
        "th": "พรหมคีรี",
        "en": "Phrom Khiri"
      },
      "changwat_id": "80"
    },
    "8003": {
      "name": {
        "th": "ลานสกา",
        "en": "Lan Saka"
      },
      "changwat_id": "80"
    },
    "8004": {
      "name": {
        "th": "ฉวาง",
        "en": "Chawang"
      },
      "changwat_id": "80"
    },
    "8005": {
      "name": {
        "th": "พิปูน",
        "en": "Phipun"
      },
      "changwat_id": "80"
    },
    "8006": {
      "name": {
        "th": "เชียรใหญ่",
        "en": "Chian Yai"
      },
      "changwat_id": "80"
    },
    "8007": {
      "name": {
        "th": "ชะอวด",
        "en": "Cha-uat"
      },
      "changwat_id": "80"
    },
    "8008": {
      "name": {
        "th": "ท่าศาลา",
        "en": "Tha Sala"
      },
      "changwat_id": "80"
    },
    "8009": {
      "name": {
        "th": "ทุ่งสง",
        "en": "Thung Song"
      },
      "changwat_id": "80"
    },
    "8010": {
      "name": {
        "th": "นาบอน",
        "en": "Na Bon"
      },
      "changwat_id": "80"
    },
    "8011": {
      "name": {
        "th": "ทุ่งใหญ่",
        "en": "Thung Yai"
      },
      "changwat_id": "80"
    },
    "8012": {
      "name": {
        "th": "ปากพนัง",
        "en": "Pak Phanang"
      },
      "changwat_id": "80"
    },
    "8013": {
      "name": {
        "th": "ร่อนพิบูลย์",
        "en": "Ron Phibun"
      },
      "changwat_id": "80"
    },
    "8014": {
      "name": {
        "th": "สิชล",
        "en": "Sichon"
      },
      "changwat_id": "80"
    },
    "8015": {
      "name": {
        "th": "ขนอม",
        "en": "Khanom"
      },
      "changwat_id": "80"
    },
    "8016": {
      "name": {
        "th": "หัวไทร",
        "en": "Hua Sai"
      },
      "changwat_id": "80"
    },
    "8017": {
      "name": {
        "th": "บางขัน",
        "en": "Bang Khan"
      },
      "changwat_id": "80"
    },
    "8018": {
      "name": {
        "th": "ถ้ำพรรณรา",
        "en": "Tham Phannara"
      },
      "changwat_id": "80"
    },
    "8019": {
      "name": {
        "th": "จุฬาภรณ์",
        "en": "Chulabhorn"
      },
      "changwat_id": "80"
    },
    "8020": {
      "name": {
        "th": "พระพรหม",
        "en": "Phra Phrom"
      },
      "changwat_id": "80"
    },
    "8021": {
      "name": {
        "th": "นบพิตำ",
        "en": "Nopphitam"
      },
      "changwat_id": "80"
    },
    "8022": {
      "name": {
        "th": "ช้างกลาง",
        "en": "Chang Klang"
      },
      "changwat_id": "80"
    },
    "8023": {
      "name": {
        "th": "เฉลิมพระเกียรติ",
        "en": "Chaloem Phra Kiet"
      },
      "changwat_id": "80"
    },
    "8101": {
      "name": {
        "th": "เมืองกระบี่",
        "en": "Mueang Krabi"
      },
      "changwat_id": "81"
    },
    "8102": {
      "name": {
        "th": "เขาพนม",
        "en": "Khao Phanom"
      },
      "changwat_id": "81"
    },
    "8103": {
      "name": {
        "th": "เกาะลันตา",
        "en": "Ko Lanta"
      },
      "changwat_id": "81"
    },
    "8104": {
      "name": {
        "th": "คลองท่อม",
        "en": "Khlong Thom"
      },
      "changwat_id": "81"
    },
    "8105": {
      "name": {
        "th": "อ่าวลึก",
        "en": "Ao Luek"
      },
      "changwat_id": "81"
    },
    "8106": {
      "name": {
        "th": "ปลายพระยา",
        "en": "Plai Phraya"
      },
      "changwat_id": "81"
    },
    "8107": {
      "name": {
        "th": "ลำทับ",
        "en": "Lam Thap"
      },
      "changwat_id": "81"
    },
    "8108": {
      "name": {
        "th": "เหนือคลอง",
        "en": "Nuea Khlong"
      },
      "changwat_id": "81"
    },
    "8201": {
      "name": {
        "th": "เมืองพังงา",
        "en": "Mueang Phang-nga"
      },
      "changwat_id": "82"
    },
    "8202": {
      "name": {
        "th": "เกาะยาว",
        "en": "Ko Yao"
      },
      "changwat_id": "82"
    },
    "8203": {
      "name": {
        "th": "กะปง",
        "en": "Kapong"
      },
      "changwat_id": "82"
    },
    "8204": {
      "name": {
        "th": "ตะกั่วทุ่ง",
        "en": "Takua Thung"
      },
      "changwat_id": "82"
    },
    "8205": {
      "name": {
        "th": "ตะกั่วป่า",
        "en": "Takua Pa"
      },
      "changwat_id": "82"
    },
    "8206": {
      "name": {
        "th": "คุระบุรี",
        "en": "Khura Buri"
      },
      "changwat_id": "82"
    },
    "8207": {
      "name": {
        "th": "ทับปุด",
        "en": "Thap Put"
      },
      "changwat_id": "82"
    },
    "8208": {
      "name": {
        "th": "ท้ายเหมือง",
        "en": "Thai Mueang"
      },
      "changwat_id": "82"
    },
    "8301": {
      "name": {
        "th": "เมืองภูเก็ต",
        "en": "Mueang Phuket"
      },
      "changwat_id": "83"
    },
    "8302": {
      "name": {
        "th": "กะทู้",
        "en": "Kathu"
      },
      "changwat_id": "83"
    },
    "8303": {
      "name": {
        "th": "ถลาง",
        "en": "Thalang"
      },
      "changwat_id": "83"
    },
    "8401": {
      "name": {
        "th": "เมืองสุราษฎร์ธานี",
        "en": "Mueang Surat Thani"
      },
      "changwat_id": "84"
    },
    "8402": {
      "name": {
        "th": "กาญจนดิษฐ์",
        "en": "Kanchanadit"
      },
      "changwat_id": "84"
    },
    "8403": {
      "name": {
        "th": "ดอนสัก",
        "en": "Don Sak"
      },
      "changwat_id": "84"
    },
    "8404": {
      "name": {
        "th": "เกาะสมุย",
        "en": "Ko Samui"
      },
      "changwat_id": "84"
    },
    "8405": {
      "name": {
        "th": "เกาะพะงัน",
        "en": "Ko Pha-ngan"
      },
      "changwat_id": "84"
    },
    "8406": {
      "name": {
        "th": "ไชยา",
        "en": "Chaiya"
      },
      "changwat_id": "84"
    },
    "8407": {
      "name": {
        "th": "ท่าชนะ",
        "en": "Tha Chana"
      },
      "changwat_id": "84"
    },
    "8408": {
      "name": {
        "th": "คีรีรัฐนิคม",
        "en": "Khiri Rat Nikhom"
      },
      "changwat_id": "84"
    },
    "8409": {
      "name": {
        "th": "บ้านตาขุน",
        "en": "Ban Ta Khun"
      },
      "changwat_id": "84"
    },
    "8410": {
      "name": {
        "th": "พนม",
        "en": "Phanom"
      },
      "changwat_id": "84"
    },
    "8411": {
      "name": {
        "th": "ท่าฉาง",
        "en": "Tha Chang"
      },
      "changwat_id": "84"
    },
    "8412": {
      "name": {
        "th": "บ้านนาสาร",
        "en": "Ban Na San"
      },
      "changwat_id": "84"
    },
    "8413": {
      "name": {
        "th": "บ้านนาเดิม",
        "en": "Ban Na Doem"
      },
      "changwat_id": "84"
    },
    "8414": {
      "name": {
        "th": "เคียนซา",
        "en": "Khian Sa"
      },
      "changwat_id": "84"
    },
    "8415": {
      "name": {
        "th": "เวียงสระ",
        "en": "Wiang Sa"
      },
      "changwat_id": "84"
    },
    "8416": {
      "name": {
        "th": "พระแสง",
        "en": "Phrasaeng"
      },
      "changwat_id": "84"
    },
    "8417": {
      "name": {
        "th": "พุนพิน",
        "en": "Phunphin"
      },
      "changwat_id": "84"
    },
    "8418": {
      "name": {
        "th": "ชัยบุรี",
        "en": "Chai Buri"
      },
      "changwat_id": "84"
    },
    "8419": {
      "name": {
        "th": "วิภาวดี",
        "en": "Chai Buri"
      },
      "changwat_id": "84"
    },
    "8501": {
      "name": {
        "th": "เมืองระนอง",
        "en": "Mueang Ranong"
      },
      "changwat_id": "85"
    },
    "8502": {
      "name": {
        "th": "ละอุ่น",
        "en": "La-un"
      },
      "changwat_id": "85"
    },
    "8503": {
      "name": {
        "th": "กะเปอร์",
        "en": "Papoe"
      },
      "changwat_id": "85"
    },
    "8504": {
      "name": {
        "th": "กระบุรี",
        "en": "Kra Buri"
      },
      "changwat_id": "85"
    },
    "8505": {
      "name": {
        "th": "สุขสำราญ",
        "en": "Suk Samran"
      },
      "changwat_id": "85"
    },
    "8601": {
      "name": {
        "th": "เมืองชุมพร",
        "en": "Mueang Chumphon"
      },
      "changwat_id": "86"
    },
    "8602": {
      "name": {
        "th": "ท่าแซะ",
        "en": "Tha Sae"
      },
      "changwat_id": "86"
    },
    "8603": {
      "name": {
        "th": "ปะทิว",
        "en": "Pathio"
      },
      "changwat_id": "86"
    },
    "8604": {
      "name": {
        "th": "หลังสวน",
        "en": "Lang Suan"
      },
      "changwat_id": "86"
    },
    "8605": {
      "name": {
        "th": "ละแม",
        "en": "Lamae"
      },
      "changwat_id": "86"
    },
    "8606": {
      "name": {
        "th": "พะโต๊ะ",
        "en": "Phato"
      },
      "changwat_id": "86"
    },
    "8607": {
      "name": {
        "th": "สวี",
        "en": "Sawi"
      },
      "changwat_id": "86"
    },
    "8608": {
      "name": {
        "th": "ทุ่งตะโก",
        "en": "Thung Tako"
      },
      "changwat_id": "86"
    },
    "9001": {
      "name": {
        "th": "เมืองสงขลา",
        "en": "Muaeng Songkhla"
      },
      "changwat_id": "90"
    },
    "9002": {
      "name": {
        "th": "สทิงพระ",
        "en": "Sathing Phra"
      },
      "changwat_id": "90"
    },
    "9003": {
      "name": {
        "th": "จะนะ",
        "en": "Chana"
      },
      "changwat_id": "90"
    },
    "9004": {
      "name": {
        "th": "นาทวี",
        "en": "Na Thawi"
      },
      "changwat_id": "90"
    },
    "9005": {
      "name": {
        "th": "เทพา",
        "en": "Thepha"
      },
      "changwat_id": "90"
    },
    "9006": {
      "name": {
        "th": "สะบ้าย้อย",
        "en": "Saba Yoi"
      },
      "changwat_id": "90"
    },
    "9007": {
      "name": {
        "th": "ระโนด",
        "en": "Ranot"
      },
      "changwat_id": "90"
    },
    "9008": {
      "name": {
        "th": "กระแสสินธุ์",
        "en": "Krasae Sin"
      },
      "changwat_id": "90"
    },
    "9009": {
      "name": {
        "th": "รัตภูมิ",
        "en": "Rattaphum"
      },
      "changwat_id": "90"
    },
    "9010": {
      "name": {
        "th": "สะเดา",
        "en": "Sadao"
      },
      "changwat_id": "90"
    },
    "9011": {
      "name": {
        "th": "หาดใหญ่",
        "en": "Hat Yai"
      },
      "changwat_id": "90"
    },
    "9012": {
      "name": {
        "th": "นาหม่อม",
        "en": "Na Mom"
      },
      "changwat_id": "90"
    },
    "9013": {
      "name": {
        "th": "ควนเนียง",
        "en": "Khuan Niang"
      },
      "changwat_id": "90"
    },
    "9014": {
      "name": {
        "th": "บางกล่ำ",
        "en": "Bang Klam"
      },
      "changwat_id": "90"
    },
    "9015": {
      "name": {
        "th": "สิงหนคร",
        "en": "Singhanakhon"
      },
      "changwat_id": "90"
    },
    "9016": {
      "name": {
        "th": "คลองหอยโข่ง",
        "en": "Khlong Hoi Khong"
      },
      "changwat_id": "90"
    },
    "9101": {
      "name": {
        "th": "เมืองสตูล",
        "en": "Mueang Satun"
      },
      "changwat_id": "91"
    },
    "9102": {
      "name": {
        "th": "ควนโดน",
        "en": "Khuan Don"
      },
      "changwat_id": "91"
    },
    "9103": {
      "name": {
        "th": "ควนกาหลง",
        "en": "Khuan Kalong"
      },
      "changwat_id": "91"
    },
    "9104": {
      "name": {
        "th": "ท่าแพ",
        "en": "Tha Phae"
      },
      "changwat_id": "91"
    },
    "9105": {
      "name": {
        "th": "ละงู",
        "en": "La-ngu"
      },
      "changwat_id": "91"
    },
    "9106": {
      "name": {
        "th": "ทุ่งหว้า",
        "en": "Thung  Wa"
      },
      "changwat_id": "91"
    },
    "9107": {
      "name": {
        "th": "มะนัง",
        "en": "Manang"
      },
      "changwat_id": "91"
    },
    "9201": {
      "name": {
        "th": "เมืองตรัง",
        "en": "Mueang Trang"
      },
      "changwat_id": "92"
    },
    "9202": {
      "name": {
        "th": "กันตัง",
        "en": "Kantang"
      },
      "changwat_id": "92"
    },
    "9203": {
      "name": {
        "th": "ย่านตาขาว",
        "en": "Yan Ta Khao"
      },
      "changwat_id": "92"
    },
    "9204": {
      "name": {
        "th": "ปะเหลียน",
        "en": "Palian"
      },
      "changwat_id": "92"
    },
    "9205": {
      "name": {
        "th": "สิเกา",
        "en": "Sikao"
      },
      "changwat_id": "92"
    },
    "9206": {
      "name": {
        "th": "ห้วยยอด",
        "en": "Huai Yot"
      },
      "changwat_id": "92"
    },
    "9207": {
      "name": {
        "th": "วังวิเศษ",
        "en": "Wang Wiset"
      },
      "changwat_id": "92"
    },
    "9208": {
      "name": {
        "th": "นาโยง",
        "en": "Na Yong"
      },
      "changwat_id": "92"
    },
    "9209": {
      "name": {
        "th": "รัษฎา",
        "en": "Ratsada"
      },
      "changwat_id": "92"
    },
    "9210": {
      "name": {
        "th": "หาดสำราญ",
        "en": "Hat Samran"
      },
      "changwat_id": "92"
    },
    "9301": {
      "name": {
        "th": "เมืองพัทลุง",
        "en": "Mueang Phatthalung"
      },
      "changwat_id": "93"
    },
    "9302": {
      "name": {
        "th": "กงหรา",
        "en": "Kong Ra"
      },
      "changwat_id": "93"
    },
    "9303": {
      "name": {
        "th": "เขาชัยสน",
        "en": "Khao Chaison"
      },
      "changwat_id": "93"
    },
    "9304": {
      "name": {
        "th": "ตะโหมด",
        "en": "Tamot"
      },
      "changwat_id": "93"
    },
    "9305": {
      "name": {
        "th": "ควนขนุน",
        "en": "Khuan Khanun"
      },
      "changwat_id": "93"
    },
    "9306": {
      "name": {
        "th": "ปากพะยูน",
        "en": "Pak Phayun"
      },
      "changwat_id": "93"
    },
    "9307": {
      "name": {
        "th": "ศรีบรรพต",
        "en": "Si Banphot"
      },
      "changwat_id": "93"
    },
    "9308": {
      "name": {
        "th": "ป่าบอน",
        "en": "Pa Bon"
      },
      "changwat_id": "93"
    },
    "9309": {
      "name": {
        "th": "บางแก้ว",
        "en": "Bang Kaeo"
      },
      "changwat_id": "93"
    },
    "9310": {
      "name": {
        "th": "ป่าพยอม",
        "en": "Pa Phayom"
      },
      "changwat_id": "93"
    },
    "9311": {
      "name": {
        "th": "ศรีนครินทร์",
        "en": "Srinagarindra"
      },
      "changwat_id": "93"
    },
    "9401": {
      "name": {
        "th": "เมืองปัตตานี",
        "en": "Mueang Pattani"
      },
      "changwat_id": "94"
    },
    "9402": {
      "name": {
        "th": "โคกโพธิ์",
        "en": "Khok Pho"
      },
      "changwat_id": "94"
    },
    "9403": {
      "name": {
        "th": "หนองจิก",
        "en": "Nong Chik"
      },
      "changwat_id": "94"
    },
    "9404": {
      "name": {
        "th": "ปะนาเระ",
        "en": "Panare"
      },
      "changwat_id": "94"
    },
    "9405": {
      "name": {
        "th": "มายอ",
        "en": "Mayo"
      },
      "changwat_id": "94"
    },
    "9406": {
      "name": {
        "th": "ทุ่งยางแดง",
        "en": "Thung Yang Daeng"
      },
      "changwat_id": "94"
    },
    "9407": {
      "name": {
        "th": "สายบุรี",
        "en": "Sai Buri"
      },
      "changwat_id": "94"
    },
    "9408": {
      "name": {
        "th": "ไม้แก่น",
        "en": "Mai Kaen"
      },
      "changwat_id": "94"
    },
    "9409": {
      "name": {
        "th": "ยะหริ่ง",
        "en": "Yaring"
      },
      "changwat_id": "94"
    },
    "9410": {
      "name": {
        "th": "ยะรัง",
        "en": "Yarang"
      },
      "changwat_id": "94"
    },
    "9411": {
      "name": {
        "th": "กะพ้อ",
        "en": "Kapho"
      },
      "changwat_id": "94"
    },
    "9412": {
      "name": {
        "th": "แม่ลาน",
        "en": "Mae Lan"
      },
      "changwat_id": "94"
    },
    "9501": {
      "name": {
        "th": "เมืองยะลา",
        "en": "Mueang Yala"
      },
      "changwat_id": "95"
    },
    "9502": {
      "name": {
        "th": "เบตง",
        "en": "Betong"
      },
      "changwat_id": "95"
    },
    "9503": {
      "name": {
        "th": "บันนังสตา",
        "en": "Bannang Sata"
      },
      "changwat_id": "95"
    },
    "9504": {
      "name": {
        "th": "ธารโต",
        "en": "Than To"
      },
      "changwat_id": "95"
    },
    "9505": {
      "name": {
        "th": "ยะหา",
        "en": "Yaha"
      },
      "changwat_id": "95"
    },
    "9506": {
      "name": {
        "th": "รามัน",
        "en": "Raman"
      },
      "changwat_id": "95"
    },
    "9507": {
      "name": {
        "th": "กาบัง",
        "en": "Kabang"
      },
      "changwat_id": "95"
    },
    "9508": {
      "name": {
        "th": "กรงปีนัง",
        "en": "Krong Pinang"
      },
      "changwat_id": "95"
    },
    "9601": {
      "name": {
        "th": "เมืองนราธิวาส",
        "en": "Mueang Narathiwat"
      },
      "changwat_id": "96"
    },
    "9602": {
      "name": {
        "th": "ตากใบ",
        "en": "Tak Bai"
      },
      "changwat_id": "96"
    },
    "9603": {
      "name": {
        "th": "บาเจาะ",
        "en": "Tak Bai"
      },
      "changwat_id": "96"
    },
    "9604": {
      "name": {
        "th": "ยี่งอ",
        "en": "Yi-ngo"
      },
      "changwat_id": "96"
    },
    "9605": {
      "name": {
        "th": "ระแงะ",
        "en": "Ra-ngae"
      },
      "changwat_id": "96"
    },
    "9606": {
      "name": {
        "th": "รือเสาะ",
        "en": "Rueso"
      },
      "changwat_id": "96"
    },
    "9607": {
      "name": {
        "th": "ศรีสาคร",
        "en": "Si Sakhon"
      },
      "changwat_id": "96"
    },
    "9608": {
      "name": {
        "th": "แว้ง",
        "en": "Waeng"
      },
      "changwat_id": "96"
    },
    "9609": {
      "name": {
        "th": "สุคิริน",
        "en": "Sukhirin"
      },
      "changwat_id": "96"
    },
    "9610": {
      "name": {
        "th": "สุไหงโก-ลก",
        "en": "Su-ngai Kolok"
      },
      "changwat_id": "96"
    },
    "9611": {
      "name": {
        "th": "สุไหงปาดี",
        "en": "Su-ngai Padi"
      },
      "changwat_id": "96"
    },
    "9612": {
      "name": {
        "th": "จะแนะ",
        "en": "Chanae"
      },
      "changwat_id": "96"
    },
    "9613": {
      "name": {
        "th": "เจาะไอร้อง",
        "en": "Cho-airong"
      },
      "changwat_id": "96"
    }
  }
},
getters: {
  getSDateLt:(state)=>{
    return state.sdateLocation
  },
  getEDateLt:(state)=>{
    return state.edateLocation
  },
  getArrDateLocation:(state)=>{
    return state.arrDateLocation
  },
  getCwIndex:(state)=>{
    return state.cwInx
},
getChangwats:(state)=>{
   let result = Object.keys(state.changwats).map((key) => {
    return {name:state.changwats[key].name.th,id:key}
   });
    //  console.log(result);
    return result
 },
getAmphoes:(state)=>{
  return state.amphoes         
},
},
mutations: {
  setSDateLt:(state)=>{
   state.sdateLocation
  },
  setEDateLt:(state)=>{
     state.edateLocation
  },
  setArrDateLocation:(state)=>{
    state.arrDateLocation
  },
    setChangwats:(state,payload)=>{
      state.changwats= payload;
     
    },
    setAmphoes:(state,payload)=>{
        state.changwats= payload;
    },
}
}